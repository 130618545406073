import { createEffect, createEvent, createStore, sample } from "effector"
import { useStore } from "effector-react"

const usePage = () => {
    return {
        data: useStore($pageStore)
    }
}

export type TPage =

    // section admin
    | '/admin/courses'
    | '/employees'
    | '/presents'
    | '/present'
    | '/students'
    | '/groups'
    | '/employee'

    // section employee
    | '/group'
    | '/lesson'
    | '/student'

    // section general
    | '/home'
    | '/user'
    | '/rating'

const setPage = createEvent<TPage>()
const returnPage = createEvent<TPage>()
const addPage = createEvent<TPage>()
const delHistoryPage = createEvent()

export const $historyStore = createStore<TPage[]>(['/user'])
    .on(addPage, (pages, page) => ([page, ...pages]))
    .on(delHistoryPage, (pages, _) => {
        pages.shift()
        return pages
    })

export const $pageStore = createStore<TPage>('/user')
    .on(returnPage, (_, page) => page)

$pageStore.watch((value) => console.log('pageStore value', value))

const pageFx = createEffect((page: TPage) => {
    if (page) {
        addPage(page)
        window.history.pushState({ page }, page)
    }
    return page
})

sample({
    clock: setPage,
    target: pageFx
})

sample({
    clock: pageFx.doneData,
    target: $pageStore
})

export const selectors = {
    usePage,
}

export const events = {
    setPage,
    returnPage,
    delHistoryPage
}


// ---------------------
