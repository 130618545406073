import { createEvent } from "effector"

export type TStudent = {
    id?: number
    firstname: string
    lastname: string
    phone: string
    image: string
    group_id: number
    rating?: number
}

const addStudent = createEvent<TStudent>()
const editStudent = createEvent<TStudent>()
const delStudent = createEvent<number>()
const setStudents = createEvent<TStudent[]>()

export const events = {
    addStudent,
    editStudent,
    delStudent,
    setStudents,
}