import { useStore } from "effector-react"
import { createEffect, createEvent, createStore, sample } from "effector"
import { TGroup } from "shared/api/events/group"
import { groupModel } from "entities/group"

const editGroup = createEvent<number>()

export const $editGroupStrore = createStore<TGroup | null>(null)

type TSelectGroups = {
    id: number
    groups: TGroup[]
}

const selectGroupsFx = createEffect(({ id, groups }: TSelectGroups): TGroup | null => {
    if (id === null) return null
    return groups.filter(c => c.id === id)[0]
})

sample({
    clock: editGroup,
    source: groupModel.$groupsStrore,
    fn: (groups, id) => ({ groups, id }),
    target: selectGroupsFx
})

sample({
    clock: selectGroupsFx.doneData,
    target: $editGroupStrore
})

const useEditGroup = () => useStore($editGroupStrore)

export const selectors = {
    useEditGroup,
}

export const events = {
    editGroup
}