import { Handler } from "..";
import { paginationModel } from "shared/ui/pdf";
import { TControl } from '@ctypes/socket/server-to-client'

class ControlHandler extends Handler {
    handle(message: TControl) {

        const action = message.payload.action

        console.log('ControlHandler  message.payload',  message.payload)

        paginationModel.events.setPage(action)
    }

}

ControlHandler.EVENT = 'control'

export {
    ControlHandler
}