import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { pageModel } from 'shared/ui/page-root';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { presentModel } from 'entities/present';

import PresentMenu from 'entities/present/ui/menu/ui';
import LabelIcon from '@mui/icons-material/Label';

export default function Presents() {

    const presents = presentModel.selectors.usePresents()

    return (
        <List sx={{ width: '100%', p: 0, m: 0 }}>

            {presents.length === 0 ? <Typography
                variant="h6"
                component="h6"
                sx={{
                    fontWeight: 'normal',
                    pt: 20, pb: 20,
                    textAlign: 'center'
                }}
            >
                Ещё нет презентаций
            </Typography> : presents.map((present, i) => {
                return (
                    <>
                        <ListItem
                            key={present.id}
                            alignItems="flex-start"
                            secondaryAction={
                                <PresentMenu id={present?.id || 0} />
                            }
                            disablePadding
                        >
                            <ListItemButton
                                onClick={(e) => {
                                    presentModel.events.selectPresent(present?.id || 0)
                                    present?.id && pageModel.events.setPage('/present')
                                }}
                                dense
                            >
                                <ListItemIcon sx={{ minWidth: '44px' }}>
                                    <LabelIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="h6"
                                            component="span"
                                            sx={{ color: 'text.primary', display: 'inline' }}
                                        >
                                            {present.title}
                                        </Typography>}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                sx={{ color: 'text.primary', display: 'inline' }}
                                            >
                                                {`${present.more}`}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                        {presents.length - 1 > i && <Divider variant="inset" component="li" sx={{ marginLeft: 0, marginRight: 0 }} />}
                    </>
                )
            })}
        </List>
    );
}