import { FC, useEffect, useState } from "react";
import { Box, Grid2 } from "@mui/material";

import { Grid2Header } from "shared/ui/grid2header";

import HomeIcon from '@mui/icons-material/Home';
import Breadcrumbs from "shared/ui/breadcrumbs/ui";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import { getRatingAPI } from "shared/api/requests/student";

import { TStudent } from "shared/api/events/student";
import { RatingList } from "widgets/rating";
import BoxCenterRoot from "shared/ui/box-center/root";
import BoxCenterHeader from "shared/ui/box-center/header";
import BoxCenterMain from "shared/ui/box-center/main";

const RatingPage: FC = () => {

    const [rating, setRating] = useState<TStudent[]>([])

    useEffect(() => {
        getRatingAPI()
            .then(rating => {
                setRating(rating.data)
            })
    }, [])

    return (
        <BoxCenterRoot mw={800}>
            <BoxCenterHeader>
                <Breadcrumbs
                    links={[
                        {
                            icon: <HomeIcon sx={{ mr: 0.8 }} fontSize="small" />,
                            name: 'Главная',
                            link: '/home'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: 'Рейтинг учеников',
                            link: '/rating'
                        },
                    ]}
                />
            </BoxCenterHeader>
            <BoxCenterMain>
                <Grid2 container spacing={2}>

                    <Grid2
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '16px'
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Рейтинг учеников" />
                            <RatingList students={rating} />
                        </Box>
                    </Grid2>
                </Grid2>
            </BoxCenterMain>
        </BoxCenterRoot>
    )
}

export default RatingPage

