import axios from "axios"
import { API_URL } from "shared/config"
import { TGrade } from "widgets/lesson/journal"

export type TReqGrade = {
    id?: number
    student_id: number
    lesson_id: number
    grade: number
}

export type TRespLessonGrade = {
    student_id: number
    grade: TGrade
}

export type TRespStudentGrade = {
    lesson_id: number
    grade: TGrade
}

export const gradeLessonAPI = (
    student_id: number, 
    lesson_id: number, 
    grade: number
) => {
    const route = API_URL + '/grade-lesson'
    return axios.post(
        route, {
            student_id,
            lesson_id,
            grade
        }, {
        withCredentials: true
    })
}

export const getGradesLessonAPI = (
    lesson_id: number,
) => {
    const route = API_URL + '/get-grades-lesson'
    return axios.post<TRespLessonGrade[]>(
        route, {
            lesson_id
        }, {
        withCredentials: true
    })
}

export const getGradesStudentAPI = (
    student_id: number,
) => {
    const route = API_URL + '/get-grades-student'
    return axios.post<TRespStudentGrade[]>(
        route, {
            student_id
        }, {
        withCredentials: true
    })
}
