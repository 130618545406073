import { FC, useEffect } from "react"

import { getCookie } from "shared/lib/get-cookie"

import { Route, Routes, useNavigate } from "react-router-dom"

import Auth from "pages/auth"

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { userAPI } from "shared/api/events";
import { connectAPI } from "shared/api/requests/user";

import Pages from "pages";

import css from './styles.module.scss'
import { userModel } from "entities/user";
import { useApp } from "./hooks/use-app";
import { connectSocketAPI } from "shared/api/connect";

const App: FC = () => {

    const navigate = useNavigate();

    const role = userModel.selectors.useUser()?.role

    useEffect(() => {

        const token = getCookie('oauth')

        console.log('token', token)

        if (!token || role === 'guest') {
            navigate('/auth')
            return
        } else {
            connectAPI()
                .then(user => {
                    console.log('connectAPI user', user)

                    user.data.role = 'admin'
                    userAPI.events.setUser(user.data)
                    
                })
                .catch(e => {
                })
            navigate('/')
        }

    }, [role, navigate])

    const { socketStatus } = useApp()

    useEffect(() => {

        const token = getCookie('oauth')

        if (!token || role === 'guest') {
            return
        }

        if (socketStatus === 'open') connectSocketAPI(token, 'present')

    }, [socketStatus, role])

    return (
        <div className={css.app}>
            <Routes>
                <Route
                    path={`/auth`}
                    element={<Auth />}
                />
                <Route
                    path={`/`}
                    element={<Pages />}
                />
            </Routes>
        </div>
    )
}

export default App