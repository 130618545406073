import { useStore } from "effector-react"
import { lessonAPI } from "shared/api/events"
import { createStore } from "effector"

export type TLesson = {
    id: number
    present_id: number
    status: 1 | 0
    access: 1 | 0 | -1
}

const {
    setLesson,
    startLesson,
    finishLesson,

    setLessons
} = lessonAPI.events

export const $lessonStore = createStore<TLesson | null>(null)
    .on(setLesson, (_, lesson) => lesson)
    .on(startLesson, (_, lesson) => lesson)
    .on(finishLesson, (lesson, _) => ({
        id: lesson?.id || 0,
        present_id: lesson?.present_id || 0,
        status: 1,
        access: 1
    }))

export const $lessonsStore = createStore<TLesson[]>([])
    .on(setLessons, (_, lessons) => lessons)

$lessonStore.watch(value => console.log('lessonStore value', value))

const useLesson = () => useStore($lessonStore)
const useLessons = () => useStore($lessonsStore)

export const selectors = {
    useLesson,
    useLessons
}

export const events = {
}