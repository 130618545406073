import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { employeesAPI } from 'shared/api/events';
import { employeeModel } from 'entities/employee';
import { featureEmployee } from 'features/employee';
import { alertModel } from 'shared/ui/alert';
import { snackbarModel } from 'shared/ui/snackbar';
import { delEmployeeAPI } from 'shared/api/requests/employee';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));


export default function UserMenu(props: { id: number }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log('handleClick')
    
    event.stopPropagation()

    employeeModel.events.selectEmployee(props.id)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>)  => {
    console.log('handleClose')
    setAnchorEl(null);
    event.stopPropagation()

    employeeModel.events.openModal(false)
    employeeModel.events.selectEmployee(0)
  };

  const userId = employeeModel.selectors.useSelectEmployee()?.id || 0

  const deleteEmployee = (e: React.FormEvent) => {

    alertModel.events.setAlert({
      status: true,
      title: 'Удалить этого сотрудника?',
      text: 'Данное действие нельзя отменить. Подумайте хорошенько :)',
      action: {
        success: {
          color: 'error',
          _click: () => delEmployeeAPI(userId)
            .then(response => {
              console.log('response?.data', response?.data)
              response?.data && employeesAPI.events.delEmployee(userId)
              employeeModel.events.selectEmployee(0)
              alertModel.events.setAlert(null);
              snackbarModel.events.setSnackbar({
                text: 'Сотрудник успешно удален!',
                severity: 'success',
                status: true
              })
              // navigate('/learn/lessons')
            })
            .catch(function (e) {
              // setDenied(true)
            }),
          text: 'Удалить'
        },
        cancel: {
          _click: () => { },
          text: 'Отмена'
        }
      }
    })

    setAnchorEl(null);
    e.preventDefault()

  }

  const editEmployee = (e: React.FormEvent) => {
    setAnchorEl(null);
    e.preventDefault()
    featureEmployee.events.editEmployee(userId)
  }

  return (
    <div>
      <IconButton
        // aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        edge="end"
        aria-label="comments"
        onClick={handleClick}
      >
        <SettingsIcon />
      </IconButton>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={editEmployee} disableRipple>
          <EditIcon />
          Редактировать
        </MenuItem>
        <MenuItem onClick={deleteEmployee} disableRipple>
          <DeleteIcon />
          Удалить
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={() => employeeModel.events.openModal(true)} disableRipple>
          <OpenInFullIcon />
          Открыть
        </MenuItem>
      </StyledMenu>
    </div>
  );
}