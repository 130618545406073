import { createEffect, createEvent, createStore, sample } from "effector"
import { useStore } from "effector-react"
import { studentAPI } from "shared/api/events"
import { TStudent } from "shared/api/events/student"

const {
    addStudent,
    editStudent,
    delStudent,
    setStudents
} = studentAPI.events

export const $studentsStrore = createStore<TStudent[] | null>(null)
    .on(setStudents, (_, students) => students)
    .on(addStudent, (students, student) => students !== null ? [student, ...students] : null)
    .on(editStudent, (students, student) => students !== null ? students.map(u => {
        if (u.id === student?.id) return student
        return u
    }) : null)
    .on(delStudent, (students, id) => {
        return students !== null ? students.filter(student => student.id !== id) : null
    })

const selectStudent = createEvent<number>()
const setSelectStudent = createEvent<TStudent | null>()

// Данные о выбранном ученике
export const $selectStudentStrore = createStore<TStudent | null>(null)
    .on(setSelectStudent, (_, student) => student)

type TSelectStudents = {
    id: number
    students: TStudent[]
}

const selectStudentFx = createEffect(({ id, students }: TSelectStudents): TStudent | null => {
    if (id === null) return null
    return students.filter(student => student.id === id)[0]
})

sample({
    clock: selectStudent,
    source: $studentsStrore,
    filter: (s: TStudent[] | null): s is TStudent[] => s !== null,
    fn: (students, id) => ({ students, id }),
    target: selectStudentFx
})

sample({
    clock: selectStudentFx.doneData,
    target: $selectStudentStrore
})

const openModal = createEvent<boolean>()
export const $openModalStore = createStore<boolean>(false)
    .on(openModal, (_, modal) => modal)

const useOpenModal = () => useStore($openModalStore)

const useStudents = () => useStore($studentsStrore)
const useSelectStudent = () => useStore($selectStudentStrore)

export const selectors = {
    useStudents,
    useSelectStudent,

    useOpenModal
}

export const events = {
    openModal,
    selectStudent,
    setSelectStudent
}