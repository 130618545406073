import { FC } from "react";

import { Divider, Typography } from "@mui/material";

import { ListGroupItem } from "entities/group/ui/list-item";

type TGroup = {
    id: number
    icon: string
    name: string
    students: number
}

type TGroupProps = {
    groups: TGroup[]
}

export const Groups: FC<TGroupProps> = ({ groups }) => {
    return (
        <>
            {groups.length > 0 ?
                groups.map(({ id, icon, name, students }, i) => {
                    return (
                        <>
                            <ListGroupItem
                                id={id}
                                icon={icon}
                                name={name}
                                students={students}
                            />
                            {groups.length - 1 > i && <Divider />}
                        </>
                    )
                }) :
                <Typography sx={{ pt: 12, pb: 12, textAlign: 'center', fontSize: '1.02rem' }}>
                    Нет групп
                </Typography>}
        </>
    )
}