import { FC } from "react";

import { formatTime } from "shared/lib/fortmat-time";
import { training_short_days } from "entities/group/lib/days";

import css from './styles.module.scss'

type Lesson = {
    day: number
    course: string
    time: number
}

type LessonProps = {
    lessons: Lesson[]
}

export const Schedule: FC<LessonProps> = ({ lessons }) => {

    return (
        <div className={css.lessons}>
            <div className={css.list}>
                <div className={css.item}>
                    <div className={css.day}>
                        День урока
                    </div>
                    <div className={css.course}>
                        Курс
                    </div>
                    <div className={css.time}>
                        Время начала
                    </div>
                </div>

                {lessons.sort((l1, l2) => String(l1.day).localeCompare(String(l2.day)) || l1.time - l2.time).map(lesson => {
                    return (
                        <div
                            key={'k' + lesson.day}
                            className={css.item}
                        >
                            <div className={css.day}>
                                <span>{training_short_days[lesson.day]}</span>
                            </div>
                            <div className={css.course}>
                                {lesson.course}
                            </div>
                            <div className={css.time}>
                                {formatTime(new Date(lesson.time))}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}