import * as React from 'react';
import { createTheme } from '@mui/material/styles';

import type { Router } from '@toolpad/core';

import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';

import Alert from 'shared/ui/alert/ui';
import CoursesPage from 'pages/admin/courses';
import PresentPage from 'pages/admin/present';
import PresentsPage from 'pages/admin/presents';
import EmployeesPage from 'pages/admin/employees';

import { SnackbarForm } from 'shared/ui/snackbar';
import { TPage } from 'shared/ui/page-root/page-root/model';
import { Page, pageModel, PageRoot } from 'shared/ui/page-root';
import { getCoursesAPI } from 'shared/api/requests/course';
import { courseAPI, employeesAPI, groupAPI } from 'shared/api/events';
import { getGroupsAPI } from 'shared/api/requests/group';
import { getEmployeesAPI } from 'shared/api/requests/employee';

import GroupsPage from './admin/groups';
import StudendsPage from './admin/students';
import GroupPage from 'pages/employee/group';
import LessonPage from 'pages/employee/lesson';
import EmployeePage from 'pages/general/employee';
import StudentPage from 'pages/general/student';
import UserPage from 'pages/general/user';
import RatingPage from 'pages/general/rating';

import AnsarLogo from 'shared/assets/icons/ansar.png'
import { userModel } from 'entities/user';

import NAVIGATION_ADMIN from 'widgets/navigation/lib/admin';
import NAVIGATION_EMPLOYEE from 'widgets/navigation/lib/employee';
import NAVIGATION_STUDENT from 'widgets/navigation/lib/student';

const customTheme = createTheme({
    cssVariables: {
        colorSchemeSelector: 'data-toolpad-color-scheme',
    },
    colorSchemes: {
        light: {
            palette: {
                background: {
                    default: '#EEEEF9',
                    paper: '#F9F9FE',
                }
            },
        },
        dark: {
            palette: {
                background: {
                    default: '#0f0f0f',
                    paper: '#212121',
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
});

interface DemoProps {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window?: () => Window;
}

export default function Pages(props: DemoProps) {
    const { window } = props;

    // const [pathname, setPathname] = React.useState<TPage>('/employees');

    React.useEffect(() => {
        getCoursesAPI()
            .then(emps => {
                courseAPI.events.setCourses(emps.data)
            })
        getGroupsAPI()
            .then(grps => {
                groupAPI.events.setGroups(grps.data)
            })
            .catch(e => {
                console.log("getGroupsAPI error", e)
            })

        getEmployeesAPI()
            .then(emps => {
                employeesAPI.events.setEmployees(emps.data)
            })

    }, [])

    const user = userModel.selectors.useUser()

    const page = pageModel.selectors.usePage().data

    const router = React.useMemo<Router>(() => {
        return {
            pathname: page,
            searchParams: new URLSearchParams(),
            navigate: (path) => pageModel.events.setPage(String(path) as TPage),
        };
    }, [page]);

    // Remove this const when copying and pasting into your project.
    const demoWindow = window !== undefined ? window() : undefined;

    const role = user?.role

    if (!role) return <></>

    const NAVIGATION = role === 'admin' ? NAVIGATION_ADMIN : role === 'employee' ? NAVIGATION_EMPLOYEE : NAVIGATION_STUDENT

    return (
        <AppProvider
            navigation={NAVIGATION}
            router={router}
            theme={customTheme}
            window={demoWindow}
            branding={{
                logo: <img src={AnsarLogo} width={34} height={34} alt="Ansar logo" />,
                title: 'ANSAR',
            }}
        >
            <DashboardLayout>

                <SnackbarForm />
                <Alert />

                <PageRoot activePage={page}>

                    <Page key="employees" id='/employees'>
                        <EmployeesPage />
                    </Page>

                    <Page key="groups" id='/groups'>
                        <GroupsPage />
                    </Page>

                    <Page key="students" id='/students'>
                        <StudendsPage />
                    </Page>

                    <Page key="courses" id='/admin/courses'>
                        <CoursesPage />
                    </Page>

                    <Page key="presents" id='/presents'>
                        <PresentsPage />
                    </Page>

                    <Page key="present" id='/present'>
                        <PresentPage />
                    </Page>

                    <Page key="employee" id='/employee'>
                        <EmployeePage />
                    </Page>

                    <Page key="group" id='/group'>
                        <GroupPage />
                    </Page>

                    <Page key="lesson" id='/lesson'>
                        <LessonPage />
                    </Page>

                    <Page key="student" id='/student'>
                        <StudentPage />
                    </Page>

                    <Page key="user" id='/user'>
                        <UserPage />
                    </Page>

                    <Page key="rating" id='/rating'>
                        <RatingPage />
                    </Page>

                </PageRoot>

            </DashboardLayout>
        </AppProvider>
    );
}



