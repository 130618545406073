import * as React from 'react';
import Button from '@mui/material/Button';

import { snackbarModel } from 'shared/ui/snackbar';
import { Box, Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

import { groupAPI } from 'shared/api/events';

import { getCookie } from 'shared/lib/get-cookie';
import { getFormData } from 'shared/lib/get-form-data';

import { PATH_COURSE_NRM } from 'shared/config';
import { IconLoader, IconPlus } from 'shared/assets/icons';

import { featureGroup } from '..';

import { addGroupAPI, editGroupAPI } from 'shared/api/requests/group';
import { training_days } from 'entities/group/lib/days';
import { courseModel } from 'entities/course';

import MultipleSelectChip from 'shared/ui/multiple-select-chip/ui';

import { employeeModel } from 'entities/employee';

import css from './styles.module.scss'

type TValue = {
    value: string
    status: null | 'error' | 'success'
}

type TSelectValue = {
    value: number | ''
    status: null | 'error' | 'success'
}

export type TTrainingValue = [number, number]

export default function AddGroup() {

    const editGroup = featureGroup.selectors.useEditGroup()
    const courses = courseModel.selectors.useCourses()
    const employees = employeeModel.selectors.useEmployees()

    console.log('editGroup', editGroup)
    console.log('employees', employees)

    const default_value = {
        value: '',
        status: null
    }

    React.useEffect(() => {
        setGroupImage({
            img: editGroup?.icon || '',
            hash: '',
            load: 0
        })
        setName({
            value: editGroup?.name || '',
            status: null
        })
        setTraining({
            value: editGroup?.training || [],
            status: null
        })
        setDay({
            value: editGroup?.training.map(v => v[0]) || [],
            status: null
        })
        setCourse({
            value: editGroup?.course || '',
            status: null
        })
        setEmployee({
            value: editGroup?.teacher || '',
            status: null
        })

    }, [editGroup])

    const [groupImage, setGroupImage] = React.useState({
        img: '',
        hash: '',
        load: 0
    });

    const [name, setName] = React.useState<TValue>(default_value);

    const [course, setCourse] = React.useState<TSelectValue>({
        value: '',
        status: null
    });

    const [employee, setEmployee] = React.useState<TSelectValue>({
        value: '',
        status: null
    });

    const [training, setTraining] = React.useState<{
        value: TTrainingValue[]
        status: null | 'error' | 'success'
    }>({
        value: [],
        status: null
    });

    const [day, setDay] = React.useState<{
        value: number[]
        status: null | 'error' | 'success'
    }>({
        value: [],
        status: null
    });

    const checkTraining = (value: TTrainingValue[]) => {
        setTraining({
            value: value.map(v => [+v[0], +v[1]]),
            status: null
        })
    }

    const checkDays = (value: string[]) => {
        setDay({
            value: value.map(v => +v),
            status: null
        })

        const tr = value.map(d => [+d, training.value.find(t => t[0] === +d)?.[1] || 0] as TTrainingValue)

        checkTraining(tr)
    }

    const checkName = (value: string) => {
        setName({
            value,
            status: null
        })
    }

    const checkCourse = (value: number) => {
        setCourse({
            value,
            status: null
        })
    }

    const checkEmployee = (value: number) => {
        setEmployee({
            value,
            status: null
        })
    }

    const reset = () => {
        setName(default_value);
        setTraining({
            value: [],
            status: null
        })
        setDay({
            value: [],
            status: null
        })
        setCourse({
            value: '',
            status: null
        });
        setEmployee({
            value: '',
            status: null
        });
        setGroupImage({
            img: '',
            hash: '',
            load: 0
        });
    }

    function checkForm(): boolean {

        const isName = name.value.trim().length > 0
        const isCourse = String(course.value).trim().length > 0
        const isEmployee = String(employee.value).trim().length > 0
        const isTraining = training.value.length > 0

        if (!isName) setName({ ...name, status: 'error' })
        else setName({ ...name, status: 'success' })

        if (!isCourse) setCourse({ ...course, status: 'error' })
        else setCourse({ ...course, status: 'success' })

        if (!isEmployee) setEmployee({ ...employee, status: 'error' })
        else setEmployee({ ...employee, status: 'success' })

        if (!isTraining) setTraining({ ...training, status: 'error' })
        else setTraining({ ...training, status: 'success' })

        return true &&
            isName &&
            isCourse &&
            isEmployee &&
            isTraining

    }

    const _addGroup = (e: React.FormEvent) => {
        e.preventDefault()

        if (!checkForm()) {
            snackbarModel.events.setSnackbar({
                text: 'Заполните все поля!',
                severity: 'error',
                status: true
            })
            return
        }

        if (!course.value) return
        if (!employee.value) return

        addGroupAPI({
            icon: groupImage.img,
            name: name.value,
            course: course.value,
            teacher: employee.value,
            training: training.value,
            students: 0
        })
            .then(response => {
                snackbarModel.events.setSnackbar({
                    text: 'Группа успешно добавлена!',
                    severity: 'success',
                    status: true
                })
                response?.data?.id && groupAPI.events.addGroup(response.data)
                reset()
            })
            .catch(function (e) { })
    }

    const _editGroup = (e: React.FormEvent) => {
        if (!editGroup?.id) return

        e.preventDefault()
        if (!checkForm()) {
            snackbarModel.events.setSnackbar({
                text: 'Заполните все поля!',
                severity: 'error',
                status: true
            })
            return
        }

        if (!course.value) return
        if (!employee.value) return

        editGroupAPI({
            id: editGroup?.id,
            icon: groupImage.img,
            name: name.value,
            course: course.value,
            teacher: employee.value,
            training: training.value,
            students: 0
        })
            .then(response => {
                snackbarModel.events.setSnackbar({
                    text: 'Группа успешно изменена!',
                    severity: 'success',
                    status: true
                })
                response?.data?.id && groupAPI.events.editGroup(response.data)
                reset()
            })
            .catch(function (e) { })
    }

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) return

        let img_file: File = e.target.files[0]
        let size_file = img_file.size / 1024 / 1024;

        if (size_file > 15) {
            //
        } else {
            if (img_file) {
                setGroupImage({
                    img: '',
                    hash: '',
                    load: 1
                });
                uploadImage(img_file);
            }
        }
    }


    const uploadImage = (file: File) => {
        console.log('uploadImage oauth', getCookie('oauth'))

        fetch('https://itansar.ru/php-server/api/upload-course.php', {
            method: 'POST',
            body: getFormData({
                file,
                oauth: JSON.stringify({
                    token: getCookie('oauth')
                })
            })
        })
            .then(response => {
                return response.json()
            })

            .then(result => {
                console.log('result', result)
                if (result.res === "ok") {
                    setGroupImage({
                        img: result.data.filename,
                        hash: result.data.hash,
                        load: 0
                    })
                } else {

                    setGroupImage({
                        img: '',
                        hash: '',
                        load: 0
                    })

                    if (result.data.code === 2) {
                    } else if (result.data.code === 3) {
                    } else if (result.data.code === 5) {
                    } else {
                    }
                }

            })
            .catch(e => {
                console.log('catche', e)
                setGroupImage({
                    img: '',
                    hash: '',
                    load: 0
                });
            });

    }

    return (
        <Container sx={{ pb: 2, pt: 1 }}>
            <form onSubmit={editGroup ? _editGroup : _addGroup}>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        gridGap: '16px',
                        mb: 0, mt: 2
                    }}
                >
                    <div className={`${css.group} ${css.flex}`}>
                        <div className={`${css.icon} ${groupImage.img ? ' ' + css.active : ''}`}>
                            {groupImage.load === 1 ?
                                <div className={css.loader}>
                                    <IconLoader className="svg-custom svg-loader" fill="#836EAD" />
                                </div> :
                                groupImage.img === '' ?
                                    <IconPlus width={34} height={34} fill="#0000001f" className="svg-custom" /> :
                                    <img
                                        src={PATH_COURSE_NRM + groupImage.img}
                                        alt='Фото'
                                    />}
                            <input
                                type='file'
                                onChange={(e) => onSelectFile(e)}
                                accept=".jpg, .jpeg, .png"
                            />
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>

                        <FormControl fullWidth>
                            <TextField
                                id="name"
                                label="Название группы"
                                value={name.value}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    checkName(event.currentTarget.value)
                                }}
                                variant="filled"
                                fullWidth
                                error={name.status === 'error'}
                            />
                        </FormControl>

                        <FormControl variant="filled" fullWidth margin="normal">
                            <InputLabel id="course-label">Курс обучения</InputLabel>
                            <Select
                                id="course"
                                labelId="course-label"
                                label="Курс обучения"
                                value={String(course.value)}
                                onChange={(event: SelectChangeEvent) => {
                                    checkCourse(event.target.value as unknown as number)
                                }}
                                variant="filled"
                                fullWidth
                                error={course.status === 'error'}
                            >
                                {courses.map(course => {
                                    return (
                                        <MenuItem value={course.id}>{course.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </Box>

                <FormControl variant="filled" fullWidth margin="normal">
                    <InputLabel id="emp-label">Преподаватель</InputLabel>
                    <Select
                        id="employee"
                        labelId="emp-label"
                        label="Преподаватель"
                        value={String(employee.value)}
                        onChange={(event: SelectChangeEvent) => {
                            checkEmployee(event.target.value as unknown as number)
                        }}
                        fullWidth
                        error={employee.status === 'error'}
                    >
                        {employees.map(emp => {
                            return (
                                <MenuItem value={emp.id}>{emp.firstname} {emp.lastname}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                <MultipleSelectChip
                    name="Учебные дни"
                    names={training_days}
                    training={training.value}
                    // value={training.value.map(day => String(day))}
                    setTraining={checkTraining}
                    days={day.value.map(v => String(v))}
                    setDays={checkDays}
                />

                <FormControl
                    fullWidth
                    margin="normal"
                >
                    <Button size="large" variant="contained" fullWidth onClick={editGroup ? _editGroup : _addGroup}>
                        {editGroup ? 'Изменить группу' : 'Создать группу'}
                    </Button>
                </FormControl>

            </form>

        </Container>
    );
}