import { createEvent } from "effector"

export type TEmployee = {
    id?: number
    firstname: string
    lastname: string
    email: string
    phone: string
    job: string
    image: string
}

const addEmployee = createEvent<TEmployee>()
const editEmployee = createEvent<TEmployee>()
const delEmployee = createEvent<number>()
const setEmployees = createEvent<TEmployee[]>()

export const events = {
    addEmployee,
    editEmployee,
    delEmployee,
    setEmployees,
}