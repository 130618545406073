import { createEvent } from "effector"
import { TCourse } from "entities/course/model"

const addCourse = createEvent<TCourse>()
const editCourse = createEvent<TCourse>()
const delCourse = createEvent<number>()
const setCourses = createEvent<TCourse[]>()

const setSelectCourse = createEvent<TCourse | null>()

export const events = {
    addCourse,
    editCourse,
    delCourse,
    setCourses,
    setSelectCourse
}