import { Divider, Typography } from "@mui/material";
import { FC } from "react";

type TProps = {
    title: string
}
export const Grid2Header: FC<TProps> = ({ title }) => {
    return (
        <>
            <Typography
                sx={{ m: 0, p: 2, pt: 1.5, pb: 1.5, fontWeight: 'bold', color: 'var(--mui-palette-text-secondary)' }}
                variant="subtitle2"
                component="div"
            >
                {title}
            </Typography>
            <Divider />
        </>
    )
}