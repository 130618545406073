import { Box, Grid2 } from "@mui/material"
import { FC, ReactNode } from "react"

const BoxCenterRoot: FC<{ children: ReactNode, mw: number }> = ({ children, mw = 800 }) => {
    return (
        <Grid2 spacing={2}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: '100%', maxWidth: `${mw}px` }}>
                    {children}
                </Box>
            </Box>
        </Grid2>
    )
}

export default BoxCenterRoot