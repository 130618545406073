import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { SelectChangeEvent } from '@mui/material/Select';
import DigitalClock from '../digital-clock';

type TProps = {
    value: number
    setValue: (value: number) => void
    open: boolean
    setOpen: (value: boolean) => void
}

export default function DialogSelect({
    open,
    setOpen,
    value,
    setValue
}: TProps) {

    const handleChange = (event: SelectChangeEvent<typeof value>) => {
        setValue(Number(event.target.value));
    };

    // const handleClickOpen = () => {
    //   setOpen(true);
    // };

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    return (
        <div>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>Выберите время</DialogTitle>
                <DialogContent>
                    <DigitalClock
                        value={value}
                        setValue={setValue}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button onClick={handleClose}>Ок</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}