import { createEvent, createStore } from "effector"
import { useStore } from "effector-react"

const setAlert = createEvent<TAlert | null>()

type TAlert = {
    status: boolean
    title: string
    text: string
    action: {
        cancel: {
            text: string
            _click: () => void
        },
        success: {
            color: 'error' | 'success' | 'primary'
            text: string
            _click: () => void
        },
    }
}

export const $alertStore = createStore<TAlert | null>(null)
    .on(setAlert, (_, alert) => alert)

const useAlert = () => useStore($alertStore)

export const selectors = {
    useAlert,
}

export const events = {
    setAlert
}