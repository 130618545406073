import axios from "axios"
import { API_URL } from "shared/config"
import { TGroup } from "../events/group"

export const addGroupAPI = (group: TGroup) => {
    const route = API_URL + '/add-group'
    return axios.post(
        route, group, {
        withCredentials: true
    })
}

export const editGroupAPI = (group: TGroup) => {
    const route = API_URL + '/edit-group'
    return axios.post(
        route, group, {
        withCredentials: true
    })
}

export const delGroupAPI = (id: number) => {
    const route = API_URL + '/del-group'
    return axios.post(
        route, { id }, {
        withCredentials: true
    })
}

export const getGroupsAPI = () => {
    const route = API_URL + '/get-groups'
    return axios.get<TGroup[]>(
        route, {
        withCredentials: true
    })
}

export const getGroupAPI = (id: number) => {
    const route = API_URL + '/get-group'
    return axios.post(
        route, { id }, {
        withCredentials: true
    })
}