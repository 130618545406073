import * as React from 'react';
import Button from '@mui/material/Button';

import { snackbarModel } from 'shared/ui/snackbar';
import { getFormData } from 'shared/lib/get-form-data';
import { getCookie } from 'shared/lib/get-cookie';
import { Box, Container, FormControl, TextField } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { presentAPI } from 'shared/api/events';

import { featurePresent } from '..';
import { addPresentAPI, editPresentAPI, getPresentRefAPI } from 'shared/api/requests/present';

import styled from '@emotion/styled';
import { PDF } from 'shared/ui/pdf/ui';
import { courseModel } from 'entities/course';
import { presentModel } from 'entities/present';

type TValue = {
    value: string
    status: null | 'error' | 'success'
}

export default function AddPresent() {

    const editPresent = featurePresent.selectors.useEditPresent()

    const selectCourseId = courseModel.selectors.useSelectCourse()?.id || 0

    const default_value = {
        value: '',
        status: null
    }

    React.useEffect(() => {
        setTitle({
            value: editPresent?.title || '',
            status: null
        })
        setMore({
            value: editPresent?.more || '',
            status: null
        })

    }, [editPresent])

    // const presentRef = presentModel.selectors.usePresentRef()

    React.useEffect(() => {
        editPresent?.id && getPresentRefAPI(editPresent.id)
            .then(ref => {
                // presentAPI.events.setPresentRef(ref.data)
                setRefCourse({
                    ref: ref.data || '',
                    hash: '',
                    load: false
                })
            })
        return () => {
            // presentAPI.events.setPresentRef(null)
        }
    }, [editPresent?.id])


    const [refCourse, setRefCourse] = React.useState({
        ref: '',
        hash: '',
        load: false
    });

    const [title, setTitle] = React.useState<TValue>(default_value);
    const [more, setMore] = React.useState<TValue>(default_value);

    const checkTitle = (value: string) => setTitle({
        value,
        status: null
    })

    const checkMore = (value: string) => setMore({
        value,
        status: null
    })

    const reset = () => {
        setTitle(default_value);
        setMore(default_value);

        setRefCourse({
            ref: '',
            hash: '',
            load: false
        });
    }

    function checkForm(): boolean {
        const isTitle = title.value.trim().length > 0
        const isMore = more.value.trim().length > 0

        if (!isTitle) setTitle({ ...title, status: 'error' })
        else setTitle({ ...title, status: 'success' })

        if (!isMore) setMore({ ...more, status: 'error' })
        else setMore({ ...more, status: 'success' })

        return true &&
            isTitle &&
            isMore
    }

    const _addPresent = (e: React.FormEvent) => {
        e.preventDefault()

        if (!checkForm()) {
            snackbarModel.events.setSnackbar({
                text: 'Заполните все поля!',
                severity: 'error',
                status: true
            })
            return
        }

        addPresentAPI({
            title: title.value,
            more: more.value,
            ref: refCourse.ref,
            course_id: selectCourseId
        })
            .then(response => {
                snackbarModel.events.setSnackbar({
                    text: 'Урок успешно добавлен!',
                    severity: 'success',
                    status: true
                })
                response?.data?.id && presentAPI.events.addPresent(response.data)
                reset()
            })
            .catch(function (e) { })
    }

    const _editPresent = (e: React.FormEvent) => {
        if (!editPresent?.id) return
        e.preventDefault()
        if (!checkForm()) {
            snackbarModel.events.setSnackbar({
                text: 'Заполните все поля!',
                severity: 'error',
                status: true
            })
            return
        }
        editPresentAPI({
            id: editPresent?.id,
            title: title.value,
            more: more.value,
            ref: refCourse.ref,
            course_id: selectCourseId
        })
            .then(response => {
                snackbarModel.events.setSnackbar({
                    text: 'Урок успешно изменен!',
                    severity: 'success',
                    status: true
                })
                response?.data?.id && presentAPI.events.editPresent(response.data)
                reset()
            })
            .catch(function (e) { })
    }

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) return

        let img_file: File = e.target.files[0]
        let size_file = img_file.size / 1024 / 1024;

        if (size_file > 15) {
            //
        } else {
            if (img_file) {
                setRefCourse({
                    ref: '',
                    hash: '',
                    load: true
                });
                uploadPDF(img_file);
            }
        }
    }


    const uploadPDF = (file: File) => {
        console.log('uploadPDF oauth', getCookie('oauth'))

        fetch('https://itansar.ru/php-server/api/upload-file.php', {
            method: 'POST',
            body: getFormData({
                file,
                oauth: JSON.stringify({
                    token: getCookie('oauth')
                })
            })
        })
            .then(response => {
                return response.json()
            })

            .then(result => {
                console.log('result', result)
                if (result.res === "ok") {
                    setRefCourse({
                        ref: result.data.filename,
                        hash: result.data.hash,
                        load: false
                    })
                } else {

                    setRefCourse({
                        ref: '',
                        hash: '',
                        load: false
                    })

                    if (result.data.code === 2) {
                    } else if (result.data.code === 3) {
                    } else if (result.data.code === 5) {
                    } else {
                    }
                }

            })
            .catch(e => {
                console.log('catche', e)
                setRefCourse({
                    ref: '',
                    hash: '',
                    load: false
                });
            });

    }

    return (
        <Container sx={{ pb: 2, pt: 2 }}>
            <form onSubmit={editPresent ? _editPresent : _addPresent}>
                <Box>
                    <PDF
                        load={refCourse.load}
                        url={refCourse.ref ? `https://itansar.ru/php-server/presents/${refCourse.ref}` : ''}
                    />
                </Box>
                {/* <Box sx={{ p: 0, m: 0, display: 'flex', justifyContent: 'center' }}> */}
                <FormControl
                    fullWidth
                    margin="normal"
                    sx={{ p: 0, m: 0, }}
                >
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                    >
                        Добавить презентацию
                        <VisuallyHiddenInput
                            type="file"
                            accept='.pdf'
                            onChange={(e) => onSelectFile(e)}
                        />
                    </Button>
                </FormControl>
                {/* </Box> */}
                <FormControl fullWidth margin="normal">
                    <TextField
                        id="title"
                        label="Название урока"
                        value={title.value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            checkTitle(event.currentTarget.value)
                        }}
                        variant="filled"
                        fullWidth
                        error={title.status === 'error'}
                    />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField
                        id="more"
                        label="Дополнительная информация"
                        value={more.value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            checkMore(event.currentTarget.value)
                        }}
                        multiline
                        rows={4}
                        variant="filled"
                        fullWidth
                        error={more.status === 'error'}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    margin="normal"
                >
                    <Button size="large" variant="contained" fullWidth onClick={editPresent ? _editPresent : _addPresent}>
                        {editPresent ? 'Сохранить урок' : 'Добавить урок'}
                    </Button>
                </FormControl>

            </form>

        </Container>
    );
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
