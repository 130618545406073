import { TSelectLessonAPI } from "@ctypes/socket/client-to-server"
import { WS } from "processes/socket"

export const selectLessonAPI = (
    ref: string,
) => {
    const data: TSelectLessonAPI = {
        event: 'selectLesson',
        payload: {
            ref
        }
    }
    WS.sendData(data)
}