import { FC, useEffect } from "react";
import { Box, Grid2 } from "@mui/material";

import { presentAPI } from "shared/api/events"
import { getPresentsAPI } from "shared/api/requests/present";
import { courseModel } from "entities/course";
import AddPresent from "features/present/add/ui";
import Presents from "widgets/presents/ui";
import Breadcrumbs from "shared/ui/breadcrumbs/ui";
import HomeIcon from '@mui/icons-material/Home';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { Grid2Header } from "shared/ui/grid2header";
import BoxCenterRoot from "shared/ui/box-center/root";
import BoxCenterHeader from "shared/ui/box-center/header";
import BoxCenterMain from "shared/ui/box-center/main";

const PresentsPage: FC = () => {

    const course = courseModel.selectors.useSelectCourse()

    useEffect(() => {
        course?.id && getPresentsAPI(course.id)
            .then(presents => {
                presentAPI.events.setPresents(presents.data)
            })
    }, [course])

    return (
        <BoxCenterRoot mw={1100}>
            <BoxCenterHeader>
                <Breadcrumbs
                    links={[
                        {
                            icon: <HomeIcon sx={{ mr: 0.8 }} fontSize="small" />,
                            name: 'Главная',
                            link: '/home'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: 'Курсы',
                            link: '/admin/courses'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: course?.name || 'Курс',
                            link: '/presents'
                        },
                    ]}
                />
            </BoxCenterHeader>
            <BoxCenterMain>
                <Grid2 container spacing={2}>

                    <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 5.5, }}>
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Добавить урок" />
                            <AddPresent />
                        </Box>
                    </Grid2>

                    <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6.5 }}>
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Все уроки" />
                            <Presents />
                        </Box>
                    </Grid2>
                </Grid2>
            </BoxCenterMain>
        </BoxCenterRoot>
    )
}

export default PresentsPage

