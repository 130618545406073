import { FC, useEffect, useState } from "react";

import axios from 'axios';

import { IconPython } from "shared/assets/icons";
import { useNavigate } from "react-router-dom";

import { API_URL } from "shared/config";

import { TUser } from "shared/api/events/user";

import { userAPI } from "shared/api/events";

import { logoutAPI } from "shared/api/requests/user";
import { userModel } from "entities/user";

import css from './styles.module.scss'

const Auth: FC = () => {

    const [login, setLogin] = useState<null | string>(null);
    const [pass, setPass] = useState<null | string>(null);
    const [remember, setRemember] = useState<boolean>(false);

    const [denied, setDenied] = useState(false)

    const navigate = useNavigate();

    const user = userModel.selectors.useUser()

    const auth = (e: React.FormEvent) => {

        e.preventDefault()

        axios.post<TUser>(
            API_URL + '/login',
            {
                login,
                pass,
                r: remember
            },
            { withCredentials: true }
        )
            .then(response => {
                const role = response.data.role

                userAPI.events.setUser(response.data)

                if (role === "student") {
                }

                if (role === "employee") {
                }

                if (role === "admin") {
                }

                navigate('/admin')
            })
            .catch(function (e) {
                setDenied(true)
            })
    }


    useEffect(() => {
        user && logoutAPI().then(() => {
            console.log('Успешно вышли из профился')
        })
    }, [])

    return (
        <div className={css.auth}>
            <div className={css.form}>

                <div className={css.header}>
                    <div className={css.icon}>
                        <IconPython width={60} height={60} />
                    </div>
                    <h1>Доступ к платформе</h1>
                </div>
                <div className={css.oauth}>
                    <form onSubmit={auth}>
                        {denied && <div className={css.denied}>Доступ запрещён!</div>}
                        <div className={css.group}>
                            <label htmlFor="username">Имя пользователя</label>
                            <input
                                type="text"
                                id="username"
                                value={login ? login : ''}
                                onChange={(e) => setLogin(e.currentTarget.value)}
                            />
                            <p className={`${login !== null ? login.trim().length === 0 ? '' : css.hidden : css.hidden}`}>Введите логин</p>
                        </div>

                        <div className={css.group}>
                            <label htmlFor="pass">Пароль</label>
                            <input
                                type="password"
                                id="pass"
                                value={pass ? pass : ''}
                                onChange={(e) => setPass(e.currentTarget.value)}
                            />
                            <p className={`${pass !== null ? pass.trim().length === 0 ? '' : css.hidden : css.hidden}`}>Введите пароль</p>
                        </div>
                        <div
                            className={css.remember}
                            onClick={() => setRemember(val => !val)}
                        >
                            <div className={`${css.checkbox} ${remember ? css.checked : ''}`}><div /></div>
                            <label>Запомнить на этом устройстве</label>
                        </div>

                        <button>
                            <span>Войти</span>
                        </button>
                    </form>
                </div>

            </div>
        </div>
    )
}

export default Auth