import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { PATH_AVATAR_MIN } from 'shared/config';
import { studentModel } from 'entities/student';
import { TStudent } from 'shared/api/events/student';

import StudentMenu from 'entities/student/ui/menu/ui';
import { ListItemButton } from '@mui/material';
import { pageModel } from 'shared/ui/page-root';

export default function Students() {

    const students: TStudent[] = studentModel.selectors.useStudents() || []

    return (
        <List sx={{ width: '100%', p: 0, m: 0 }}>

            {students.length === 0 ? <Typography variant="h6" component="h6" sx={{ pt: 12, pb: 12, textAlign: 'center' }}>
                Нет учеников
            </Typography> : students.map((student, i) => {
                return (
                    <>
                        <ListItem
                            disablePadding
                            key={student.id}
                            sx={{ cursor: 'pointer' }}
                            alignItems="flex-start"
                            secondaryAction={
                                <StudentMenu id={student?.id || 0} />
                            }
                            onClick={(e) => {
                                studentModel.events.selectStudent(student?.id || 0)
                                student.id && pageModel.events.setPage('/student')
                            }}
                        >
                            <ListItemButton>
                                <ListItemAvatar>
                                    <Avatar alt={`${student.firstname} ${student.lastname}`} src={PATH_AVATAR_MIN + student.image} />
                                </ListItemAvatar>
                                <ListItemText
                                    onClick={() => {
                                        studentModel.events.selectStudent(student?.id || 0)
                                        student?.id && studentModel.events.openModal(true)
                                    }}
                                    primary={`${student.firstname} ${student.lastname}`}
                                    // secondary={
                                    //     <Typography
                                    //         component="span"
                                    //         variant="body2"
                                    //         sx={{ color: 'text.primary', display: 'inline' }}
                                    //     >
                                    //         {`${student.phone}`}
                                    //     </Typography>
                                    // }
                                />
                            </ListItemButton>
                        </ListItem>
                        {students.length - 1 > i && <Divider variant="inset" component="li" />}
                    </>
                )
            })}
        </List>
    );
}