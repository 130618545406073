import { FC } from "react";

import { userModel } from "entities/user";

import EmployeeContainer from "widgets/employee";
import StudentContainer from "widgets/student";

const UserPage: FC<{}> = ({ }) => {

    const user = userModel.selectors.useUser()

    if (user?.role === 'student') return (
        <StudentContainer student={user.user} />
    )

    if (user?.role === 'employee') return (
        <EmployeeContainer employee={user.user} />
    )

    if (user?.role === 'admin') return (
        <EmployeeContainer employee={user.user} />
    )

    return <>Возникла неизвестная ошибка</>

}

export default UserPage

