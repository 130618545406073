import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { PATH_AVATAR_MIN } from 'shared/config';
import { employeeModel } from 'entities/employee';
import { pageModel } from 'shared/ui/page-root';
import { ListItemButton } from '@mui/material';

import UserMenu from 'entities/employee/ui/menu/ui';

export default function Employees() {

    const employees = employeeModel.selectors.useEmployees()

    const employeePage = (id: number) => {
        console.log('employeePage id', id)

        if(!id) return

        employeeModel.events.selectEmployee(id)
        pageModel.events.setPage('/employee')

    }

    return (
        <List>

            {employees.length === 0 ? <Typography variant="h6" component="h6" sx={{ p: 6, textAlign: 'center' }}>
                Нет сотрудников
            </Typography> : employees.map((employee, i) => {
                return (
                    <>
                        <ListItem
                            disablePadding
                            key={employee.id}
                            sx={{ cursor: 'pointer' }}
                            alignItems="flex-start"
                            secondaryAction={
                                <UserMenu id={employee?.id || 0} />
                            }
                            onClick={() => employeePage(employee?.id || 0)}
                        >
                            <ListItemButton>
                                <ListItemAvatar>
                                    <Avatar alt={`${employee.firstname} ${employee.lastname}`} src={PATH_AVATAR_MIN + employee.image} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${employee.firstname} ${employee.lastname}`}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="subtitle2"
                                                sx={{ color: 'text.primary', display: 'inline' }}
                                            >
                                                {`${employee.job}`}
                                            </Typography>
                                            {/* {` | ${employee.phone} | ${employee.email}`} */}
                                        </React.Fragment>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                        {employees.length - 1 > i && <Divider variant="inset" component="li" />}
                    </>
                )
            })}
        </List>
    );
}