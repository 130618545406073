import { FC } from "react";

import { formatTime } from "shared/lib/fortmat-time";
import { training_short_days } from "entities/group/lib/days";

import { Typography } from "@mui/material";

import css from './styles.module.scss'

type TSchedule = {
    day: number
    group: string
    course: string
    time: number
}

type ScheduleProps = {
    schedule: TSchedule[]
}

export const Schedule: FC<ScheduleProps> = ({ schedule }) => {

    return (
        <div className={css.lessons}>
            <div className={css.list}>

                <div className={css.item}>
                    <div className={css.day}>
                        День
                    </div>
                    <div className={css.group}>
                        Группа
                    </div>
                    <div className={css.course}>
                        Курс
                    </div>
                    <div className={css.time}>
                        Время
                    </div>
                </div>

                {schedule.length > 0 ? schedule.sort((l1, l2) => String(l1.day).localeCompare(String(l2.day)) || l1.time - l2.time).map(lesson => {
                    return (
                        <div
                            key={'k' + lesson.day}
                            className={css.item}
                        >
                            <div className={css.day}>
                                <span>{training_short_days[lesson.day]}</span>
                            </div>
                            <div className={css.group}>
                                {lesson.group}
                            </div>
                            <div className={css.course}>
                                {lesson.course}
                            </div>
                            <div className={css.time}>
                                {formatTime(new Date(lesson.time))}
                            </div>
                        </div>
                    )
                }) : <Typography sx={{ pt: 12, pb: 12, textAlign: 'center', fontSize: '1.02rem' }}>
                    Нет уроков
                </Typography>}
            </div>
        </div>
    )
}