import { createEvent } from "effector"
import { TEmployee } from "./employee"
import { TStudent } from "./student"

export type TRole = "employee" | "student" | "admin" | "guest"

type TUserEmployee = {
    role: "employee"
    user: TEmployee
}
type TUserStudent = {
    role: "student"
    user: TStudent
}
type TUserAdmin = {
    role: "admin"
    user: TEmployee
}

type TUserGuest = {
    role: "guest"
    user: null
}

export type TUser = TUserEmployee | TUserStudent | TUserAdmin | TUserGuest

const setUser = createEvent<TUser | null>()

export const events = {
    setUser,
}