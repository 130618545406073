import { Typography } from "@mui/material";
import { FC } from "react";

type TProps = {
    title: string
    sub_title: string
}
export const GridTitle: FC<TProps> = ({ title, sub_title }) => {
    return (
        <>
            <Typography sx={{ m: 0, p: 0, ml: 0.5, fontWeight: 'bold', }} variant="h5" component="div">
                {title}
            </Typography>
            <Typography sx={{ m: 0, mb: 1, p: 0, ml: 0.5, color: 'var(--mui-palette-text-secondary)' }} variant="subtitle1" component="div">
                {sub_title}
            </Typography>
        </>
    )
}