import { FC } from "react";

import { Box, Button } from "@mui/material";
import { courseModel } from "entities/course";
import { pageModel } from "shared/ui/page-root";
import { LEARN_URL, PATH_COURSE_NRM } from "shared/config";

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import CourseMenu from "../menu/ui";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

import { goLink } from "shared/lib/go-link";

import CourseLogo from 'shared/assets/icons/course.png'

import css from './styles.module.scss'

type TCourseProps = {
    id: number
    hash: string
    icon: string
    title: string
    more: string
    lessons: number
}

export const CardItemCourse: FC<TCourseProps> = ({
    id,
    hash,
    icon,
    title,
    more,
    lessons
}) => {

    const open = () => {
        courseModel.events.selectCourse(id)
        pageModel.events.setPage('/presents')
        // employee?.id && employeeModel.events.openModal(true)
    }

    const openLearn = () => {
        const ref = LEARN_URL + '/course/' + hash
        goLink(ref)
    }

    return (
        <Box
            sx={{
                bgcolor: 'background.paper',
                border: 1,
                borderRadius: 1,
                borderColor: 'var(--mui-palette-divider)',
            }}
            className={css.card}
        >
            <div className={css.header}>
                <div className={css.snack}>

                    <div className={css.icon}>
                        <img
                            width={54}
                            alt={title}
                            src={icon ? PATH_COURSE_NRM + icon : CourseLogo}
                        />
                    </div>

                    <div className={css.title}>
                        <div className={css.name}>
                            {title}
                        </div>
                        <div className={css.lessons}>
                            <ImportContactsIcon className={`${css.svg} custom-svg`} fontSize="small" />
                            <b>{lessons}</b>
                            <span>
                                {(() => lessons > 4 ? 'уроков' :
                                    lessons > 1 ? 'урока' :
                                        lessons > 0 ? 'урок' : 'уроков')()}
                            </span>
                        </div>
                    </div>

                </div>

                <div className={css.stat}>
                    <CourseMenu id={id} />
                </div>
            </div>
            <div className={css.main}>
                <div className={css.more}>{more}</div>
            </div>
            <div className={css.menu}>

                <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    startIcon={<OpenInFullIcon />}
                    onClick={open}
                >
                    Раскрыть
                </Button>

                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={openLearn}
                    startIcon={<OpenInNewIcon />}
                >
                    Открыть
                </Button>

            </div>
        </Box>
    )
}