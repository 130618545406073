import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Button, Checkbox, ListItemText } from '@mui/material';
import DialogSelect from '../dialog-select';
import { TTrainingValue } from 'features/group/add/ui';
import { formatTime } from 'shared/lib/fortmat-time';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, value: readonly string[], theme: Theme) {
    return {
        fontWeight: value.includes(name)
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
    };
}

// type TTrainingValue = [string, string]

export type TSelectItem = {
    [k: number | string]: string
}

type TProps = {
    name: string
    names: TSelectItem

    training: TTrainingValue[]
    setTraining: (value: TTrainingValue[]) => void

    days: string[]
    setDays: (value: string[]) => void
}

export default function MultipleSelectChip({ name, setTraining, names, training, setDays, days }: TProps) {
    const theme = useTheme();

    const handleChange = (event: SelectChangeEvent<typeof days>) => {
        const {
            target: { value },
        } = event;

        setDays(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const [selectedItem, setSelectedItem] = React.useState<number>(1)

    const [modalTimePicker, setModalTimePicker] = React.useState<boolean>(false)

    const handleTime = (time: number) => {
        console.log('handleTime time', time)
        setTraining(training.map(v => {
            return v[0] !== selectedItem ? v : [v[0], time]
        }))
    }

    return (
        <div>
            <DialogSelect
                open={modalTimePicker}
                setOpen={setModalTimePicker}
                setValue={handleTime}
                value={(training.find(v => v[0] === selectedItem)?.[1] || 0) as number}
            />
            <FormControl fullWidth margin='normal'>
                <InputLabel id="multiple-chip-label">{name}</InputLabel>
                <Select
                    labelId="multiple-chip-label"
                    id="multiple-chip"
                    multiple
                    minRows={2}
                    value={days}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label={name} />}
                    renderValue={(selected) => {
                        console.log('>> selected', selected)
                        return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={String(value)} label={names[value]} />
                                ))}
                            </Box>
                        )
                    }}
                    MenuProps={MenuProps}
                >
                    {Object.entries(names).map(([k, name]) => (
                        <MenuItem
                            key={k}
                            value={k}
                            style={getStyles(name, days.map(v => String(v)), theme)}
                        >
                            <Checkbox checked={days.includes(k)} />
                            <ListItemText primary={name} />
                            <Box>
                                <Button
                                    color='inherit'
                                    size='medium'
                                    variant='outlined'
                                    onClick={(e) => {
                                        setSelectedItem(+k)
                                        setModalTimePicker(true)
                                        e.stopPropagation()
                                    }}>
                                    {(() => {
                                        const time = new Date(training.find(v => v[0] === +k)?.[1] || 0)
                                        return formatTime(time)
                                    })()}
                                </Button>
                            </Box>

                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}