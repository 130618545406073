import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { pageModel } from 'shared/ui/page-root';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { presentModel } from 'entities/present';

import LabelIcon from '@mui/icons-material/Label';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import LockIcon from '@mui/icons-material/Lock';
import LockClockIcon from '@mui/icons-material/LockClock';

import { lessonModel } from 'entities/lesson';
import { TTooltip } from 'shared/ui/tooltip';

import { Grade } from '..';

import css from './styles.module.scss'

type TLessonsProps = {
    grades: Grade[]
}

const Lessons: React.FC<TLessonsProps> = ({ grades }) => {

    const presents = presentModel.selectors.usePresents().reverse()
    const lessons = lessonModel.selectors.useLessons()

    return (
        <List sx={{ width: '100%', p: 0, m: 0 }}>

            {presents.length === 0 ? <Typography
                variant="h6"
                component="h6"
                sx={{
                    fontWeight: 'normal',
                    pt: 20, pb: 20,
                    textAlign: 'center'
                }}
            >
                Ещё нет уроков
            </Typography> : presents.map((present, i) => {
                const lesson = lessons.find(lesson => lesson.present_id === present.id)

                const status = lesson?.status
                const lesson_id = lesson?.id

                const grade = grades.find(gr => gr.lesson_id === lesson_id)?.grade

                return (
                    <React.Fragment key={present.id}>
                        <ListItem
                            alignItems="flex-start"
                            secondaryAction={
                                <div className={css.stack}>
                                    {Number(grade) > 0 && <TTooltip title={`За урок получено ${grade} балл(а|ов)`}>
                                        <div className={css.grade}>
                                            <span>
                                                {grade}
                                            </span>
                                        </div>
                                    </TTooltip>}
                                    {
                                        status === 0 ?
                                            <TTooltip title="Урок идёт">
                                                <LockClockIcon />
                                            </TTooltip>
                                            : status === 1 ?
                                                <TTooltip title="Урок завершён">
                                                    <DoneAllIcon color='success' />
                                                </TTooltip>
                                                :
                                                <TTooltip title="Урок ещё не пройден">
                                                    <LockIcon />
                                                </TTooltip>}
                                </div>
                                // <PresentMenu id={present?.id || 0} />
                            }
                            disablePadding
                        >
                            <ListItemButton
                                onClick={(e) => {
                                    presentModel.events.selectPresent(present?.id || 0)
                                    present?.id && pageModel.events.setPage('/lesson')
                                }}
                                dense
                            >
                                <ListItemIcon sx={{ minWidth: '44px' }}>
                                    <LabelIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="h6"
                                            component="span"
                                            sx={{ color: 'text.primary', display: 'inline' }}
                                        >
                                            {present.title}
                                        </Typography>}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                sx={{ color: 'text.primary', display: 'inline' }}
                                            >
                                                {`${present.more}`}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItemButton>
                        </ListItem >
                        {presents.length - 1 > i && <Divider variant="inset" component="li" sx={{ marginLeft: 0, marginRight: 0 }} />}
                    </React.Fragment>
                )
            })}
        </List >
    );
}

export default Lessons