
export const PATH_AVATAR_MIN = "https://itansar.ru/php-server/images/min/"
export const PATH_AVATAR_NRM = "https://itansar.ru/php-server/images/nrm/"
export const PATH_AVATAR_BIG = "https://itansar.ru/php-server/images/big/"

export const PATH_COURSE_NRM = "https://itansar.ru/php-server/courses/nrm/"

// export const API_URL = "https://192.168.43.90:5000/api"
// export const LEARN_URL = "https://192.168.43.90:3008/learn"
// export const WS_HOSTNAME = 'wss://192.168.43.90:5000/ws'

export const API_URL = "https://itansar.ru/api"
export const LEARN_URL = "https://itansar.ru/learn"
export const WS_HOSTNAME = "wss://itansar.ru/ws"