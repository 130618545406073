import { FC } from "react";
import { Box, Grid2 } from "@mui/material";

import AddCourse from "features/course/add/ui";
import Courses from "widgets/courses/ui";
import Breadcrumbs from "shared/ui/breadcrumbs/ui";

import HomeIcon from '@mui/icons-material/Home';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import { GridTitle } from "shared/ui/grid-title";
import { Grid2Header } from "shared/ui/grid2header";
import BoxCenterRoot from "shared/ui/box-center/root";
import BoxCenterHeader from "shared/ui/box-center/header";
import BoxCenterMain from "shared/ui/box-center/main";

const CoursesPage: FC = () => {
    return (
        <BoxCenterRoot mw={1100}>
            <BoxCenterHeader>
                <Breadcrumbs
                    links={[
                        {
                            icon: <HomeIcon sx={{ mr: 0.8 }} fontSize="small" />,
                            name: 'Главная',
                            link: '/home'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: 'Курсы',
                            link: '/admin/courses'
                        },
                    ]}
                />
            </BoxCenterHeader>
            <BoxCenterMain>

                <GridTitle
                    title="Курсы"
                    sub_title="Список обучающих материалов"
                />

                <Grid2 container spacing={2}>

                    <Grid2
                        size={{ xs: 12, sm: 12, md: 12, lg: 6, }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Новый курс" />
                            <AddCourse />
                        </Box>
                    </Grid2>

                    <Grid2
                        size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '16px'
                            }}
                        >
                            <Courses />
                        </Box>
                    </Grid2>

                </Grid2>
            </BoxCenterMain>
        </BoxCenterRoot >
    )
}

export default CoursesPage

