import axios from "axios"

import { API_URL } from "shared/config"
import { TCourse } from "entities/course/model"

export type TReqCourse = {
    id?: number
    icon: string
    name: string
    more: string
    lessons: number
    lang: '' | 1 | 2
}

export const addCourseAPI = (course: TReqCourse) => {
    const route = API_URL + '/add-course'
    return axios.post(
        route, course, {
        withCredentials: true
    })
}

export const editCourseAPI = (course: TReqCourse) => {
    const route = API_URL + '/edit-course'
    return axios.post(
        route, course, {
        withCredentials: true
    })
}

export const delCourseAPI = (id: number) => {
    const route = API_URL + '/del-course'
    return axios.post(
        route, { id }, {
        withCredentials: true
    })
}

export const getCoursesAPI = () => {
    const route = API_URL + '/get-courses'
    return axios.get<TCourse[]>(
        route, {
        withCredentials: true
    })
}

export const getCourseAPI = (id: number) => {
    const route = API_URL + '/get-course'
    return axios.post<TCourse>(
        route, { id }, {
        withCredentials: true
    })
}