import type { Navigation } from '@toolpad/core';

import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

const NAVIGATION_EMPLOYEE: Navigation = [
    {
        kind: 'header',
        title: 'Навигатор',
    },
    {
        segment: 'home',
        title: 'Главная',
        icon: <HomeIcon />,
    },
    {
        segment: 'user',
        title: 'Мой профиль',
        icon: <PersonIcon />,
    },
    {
        segment: 'rating',
        title: 'Рейтинг учеников',
        icon: <TrendingUpIcon />,
    },
    {
        kind: 'divider',
    },
    {
        kind: 'header',
        title: 'Дополнительно',
    },
    {
        segment: 'employees',
        title: 'Сотрудники',
        icon: <PeopleAltIcon />,
    },
    {
        kind: 'divider',
    },
    {
        kind: 'header',
        title: 'Дополнительн',
    },
    {
        action: <GroupsIcon />,
        segment: 'students',
        title: 'Ученики',
        icon: <GroupsIcon />,

    }
];

export default NAVIGATION_EMPLOYEE