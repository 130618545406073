import { createEffect, createEvent, createStore, sample } from "effector"
import { useStore } from "effector-react"
import { employeesAPI } from "shared/api/events"
import { TEmployee } from "shared/api/events/employee"

const {
    addEmployee,
    editEmployee,
    delEmployee,
    setEmployees
} = employeesAPI.events

export const $employeesStrore = createStore<TEmployee[]>([])
    .on(setEmployees, (_, employees) => employees)
    .on(addEmployee, (employees, employee) => [employee, ...employees])
    .on(editEmployee, (employees, employee) => employees.map(u => {
        if (u.id === employee?.id) return employee
        return u
    }))
    .on(delEmployee, (employees, id) => {
        return employees.filter(employee => employee.id !== id)
    })

const selectEmployee = createEvent<number>()

// Данные о выбранном сотруднике
export const $selectEmployeeStrore = createStore<TEmployee | null>(null)

type TSelectEmployees = {
    id: number
    employees: TEmployee[]
}

const selectEmployeeFx = createEffect(({ id, employees }: TSelectEmployees): TEmployee | null => {
    if (id === null) return null
    return employees.filter(employee => employee.id === id)[0]
})

sample({
    clock: selectEmployee,
    source: $employeesStrore,
    fn: (employees, id) => ({ employees, id }),
    target: selectEmployeeFx
})

sample({
    clock: selectEmployeeFx.doneData,
    target: $selectEmployeeStrore
})

const openModal = createEvent<boolean>()
export const $openModalStore = createStore<boolean>(false)
    .on(openModal, (_, modal) => modal)
const useOpenModal = () => useStore($openModalStore)

const useEmployees = () => useStore($employeesStrore)
const useSelectEmployee = () => useStore($selectEmployeeStrore)

export const selectors = {
    useEmployees,
    useSelectEmployee,

    useOpenModal
}

export const events = {
    openModal,
    selectEmployee
}