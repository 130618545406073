import { FC } from "react";

import { pageModel } from "shared/ui/page-root";
import { PATH_COURSE_NRM } from "shared/config";

import ImportContactsIcon from '@mui/icons-material/ImportContacts';

import { ListItem, ListItemButton } from "@mui/material";

import CourseLogo from 'shared/assets/icons/course.png'

import css from './styles.module.scss'

type TCourse = {
    id: number
    icon: string
    name: string
    lessons: number
}

export const ListCourseItem: FC<TCourse> = ({ id, icon, name, lessons }) => {

    const open = () => {
        // groupModel.events.selectGroup(id)
        pageModel.events.setPage('/students')
    }

    return (
        <ListItem disablePadding>
            <ListItemButton sx={{ p: 2 }}>
                <div className={css.group_item}>
                    <div className={css.snack}>
                        <div className={css.icon}>
                            <img
                                width={54}
                                alt={name}
                                src={icon ? PATH_COURSE_NRM + icon : CourseLogo}
                            />
                        </div>
                        <div className={css.title}>
                            <div className={css.name}>
                                {name}
                            </div>
                            <div className={css.students}>
                                <ImportContactsIcon className={`${css.svg} custom-svg`} fontSize="small" />
                                <b>{lessons}</b>
                                <span>
                                    {(() => {
                                        return lessons > 4 ? 'уроков' :
                                            lessons > 1 ? 'урока' :
                                                lessons > 0 ? 'урок' : 'уроков'
                                    })()}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={css.stat}>
                        {/* <GroupMenu id={id} /> */}
                    </div>

                </div>
            </ListItemButton>

        </ListItem>
    )
}