import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { presentAPI } from 'shared/api/events';
import { snackbarModel } from 'shared/ui/snackbar';

import { alertModel } from 'shared/ui/alert';
import { presentModel } from 'entities/present';
import { delPresentAPI } from 'shared/api/requests/present';
import { featurePresent } from 'features/present';
import { pageModel } from 'shared/ui/page-root';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));


export default function PresentMenu(props: { id: number }) {

  const page = pageModel.selectors.usePage().data

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log('handleClick')
    event.stopPropagation()
    presentModel.events.selectPresent(props.id)
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    console.log('handleClose')
    setAnchorEl(null);
    presentModel.events.openModal(false)
    presentModel.events.selectPresent(0)
  };

  const presentId = presentModel.selectors.useSelectPresent()?.id || 0

  console.log('presentId', presentId)

  const deletePresent = (e: React.FormEvent) => {

    alertModel.events.setAlert({
      status: true,
      title: 'Удалить данный урок?',
      text: 'Данное действие нельзя отменить. Вы уверены, что хотите удалить этот урок?',
      action: {
        success: {
          color: 'error',
          _click: () => delPresentAPI(presentId)
            .then(response => {
              console.log('response?.data', response?.data)
              response?.data && presentAPI.events.delPresent(presentId)
              presentModel.events.selectPresent(0)
              alertModel.events.setAlert(null);
              snackbarModel.events.setSnackbar({
                text: 'Урок успешно удален!',
                severity: 'success',
                status: true
              })
              page !== '/presents' && pageModel.events.setPage('/presents')
              // navigate('/learn/lessons')
            })
            .catch(function (e) {
              // setDenied(true)
            }),
          text: 'Удалить'
        },
        cancel: {
          _click: () => { },
          text: 'Отмена'
        }
      }
    })

    setAnchorEl(null);
    e.preventDefault()

  }

  const editPresent = (e: React.FormEvent) => {
    setAnchorEl(null);
    e.preventDefault()
    featurePresent.events.editPresent(presentId)
    page !== '/presents' && pageModel.events.setPage('/presents')
  }

  return (
    <div>
      <IconButton
        // aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        edge="end"
        aria-label="comments"
        onClick={handleClick}
      >
        <SettingsIcon />
      </IconButton>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={editPresent} disableRipple>
          <EditIcon />
          Редактировать
        </MenuItem>
        <MenuItem onClick={deletePresent} disableRipple>
          <DeleteIcon />
          Удалить
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        {page !== '/present' ?
          <MenuItem onClick={() => pageModel.events.setPage('/present')} disableRipple>
            <OpenInFullIcon />
            Открыть
          </MenuItem> :
          <MenuItem onClick={() => {
            presentModel.events.selectPresent(0)
            pageModel.events.setPage('/presents')
          }} disableRipple>
            <CloseFullscreenIcon />
            Закрыть
          </MenuItem>}
      </StyledMenu>
    </div>
  );
}