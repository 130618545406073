import { createEffect, createEvent, createStore, sample } from "effector"
import { useStore } from "effector-react"
import { presentAPI } from "shared/api/events"

export type TPresent = {
    id: number
    hash: string
    title: string
    more: string
    ref: string
    course_id: number
}

const {
    addPresent,
    editPresent,
    delPresent,
    setPresents,
    setPresentRef
} = presentAPI.events

export const $presentsStrore = createStore<TPresent[]>([])
    .on(setPresents, (_, presents) => presents)
    .on(addPresent, (presents, present) => [present, ...presents])
    .on(editPresent, (presents, present) => presents.map(c => {
        if (c.id === present?.id) return present
        return c
    }))
    .on(delPresent, (presents, id) => {
        return presents.filter(present => present.id !== id)
    })

export const $presentRefStrore = createStore<string | 'no_access' | null>(null)
    .on(setPresentRef, (_, ref) => ref)

const selectPresent = createEvent<number>()

export const $selectPresentStrore = createStore<TPresent | null>(null)

type TSelectPresents = {
    id: number
    presents: TPresent[]
}

const selectPresentFx = createEffect(({ id, presents }: TSelectPresents): TPresent | null => {
    if (id === null) return null
    return presents.filter(present => present.id === id)[0]
})

sample({
    clock: selectPresent,
    source: $presentsStrore,
    fn: (presents, id) => ({ presents, id }),
    target: selectPresentFx
})

sample({
    clock: selectPresentFx.doneData,
    target: $selectPresentStrore
})

const openModal = createEvent<boolean>()
export const $openModalStore = createStore<boolean>(false)
    .on(openModal, (_, modal) => modal)

const useOpenModal = () => useStore($openModalStore)

const usePresents = () => useStore($presentsStrore)
const useSelectPresent = () => useStore($selectPresentStrore)

const usePresentRef = () => useStore($presentRefStrore)

export const selectors = {
    usePresents,
    useSelectPresent,

    usePresentRef,

    useOpenModal
}

export const events = {
    openModal,
    selectPresent
}