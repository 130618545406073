import { FC, useEffect, useState } from "react";
import { Box, Grid2 } from "@mui/material";

import { Grid2Header } from "shared/ui/grid2header";

import Breadcrumbs from "shared/ui/breadcrumbs/ui";
import HomeIcon from '@mui/icons-material/Home';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import { getStudentAPI } from "shared/api/requests/student";
import { studentModel } from "entities/student";
import { TGroup } from "shared/api/events/group";
import { TCourse } from "entities/course/model";
import { CardUser } from "entities/student/ui/card";
import { ListGroupItem } from "entities/group/ui/list-item";
import { getGradesStudentAPI } from "shared/api/requests/grade";
import { TGrade } from "widgets/lesson/journal";
import { Schedule } from "widgets/employee/schedule";

import Lessons from "widgets/student/lessons/ui";

import { TStudent } from "shared/api/events/student";
import BoxCenterRoot from "shared/ui/box-center/root";
import BoxCenterHeader from "shared/ui/box-center/header";
import BoxCenterMain from "shared/ui/box-center/main";
import { getPresentsAPI } from "shared/api/requests/present";
import { courseAPI, groupAPI, presentAPI } from "shared/api/events";
import { getGroupAPI } from "shared/api/requests/group";
import { groupModel } from "entities/group";
import { courseModel } from "entities/course";
import { getCourseAPI } from "shared/api/requests/course";

export type Grade = {
    lesson_id: number
    grade: TGrade
}

type TSchedule = {
    day: number
    group: string
    course: string
    time: number
}

type TProps = {
    student: TStudent
}

const StudentContainer: FC<TProps> = ({ student }) => {

    const selectGroup = groupModel.selectors.useSelectGroup()
    const selectCourse = courseModel.selectors.useSelectCourse()

    const [group, setGroup] = useState<TGroup | null>(null)
    const [course, setCourse] = useState<TCourse | null>(null)

    const [schedule, setSchedule] = useState<TSchedule[]>([])

    const [grades, setGrades] = useState<Grade[]>([])

    console.log('StudentContainer group', group)

    useEffect(() => {
        selectGroup?.id !== student.group_id && getGroupAPI(student.group_id)
            .then(group => {
                groupAPI.events.setSelectGroup(group.data)
            })
    }, [selectGroup, student.group_id])

    useEffect(() => {
        selectGroup?.course && selectCourse?.id !== selectGroup.course && getCourseAPI(selectGroup.course)
            .then(course => {
                courseAPI.events.setSelectCourse(course.data)
            })
    }, [selectCourse, selectGroup])

    useEffect(() => {
        group?.course && getPresentsAPI(group.course)
            .then(presents => {
                presentAPI.events.setPresents(presents.data)
            })
    }, [group])

    useEffect(() => {
        student?.id && getStudentAPI(student.id)
            .then(student => {

                const _schedule: TSchedule[] = []

                student.data.group.training?.forEach(([day, time]) => {
                    _schedule.push({
                        day,
                        group: student.data.group.name,
                        course: student.data.course.name,
                        time
                    })
                });

                setGroup(student.data.group)
                setCourse(student.data.course)
                setSchedule(_schedule)

            })
    }, [student])

    useEffect(() => {
        student?.id && getGradesStudentAPI(student.id)
            .then(grades => {
                setGrades(grades.data)
            })
    }, [student])

    if (!student || !group || !course) return <></>

    return (
        <BoxCenterRoot mw={1100}>
            <BoxCenterHeader>
                <Breadcrumbs
                    links={[
                        {
                            icon: <HomeIcon sx={{ mr: 0.8 }} fontSize="small" />,
                            name: 'Главная',
                            link: '/home'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: 'Ученики',
                            link: '/students'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: `${student?.firstname} ${student?.lastname}`,
                            link: '/student'
                        },
                    ]}
                />
            </BoxCenterHeader>
            <BoxCenterMain>
                <Grid2 container spacing={2}>

                    <Grid2
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 5,
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '16px'
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Об ученике" />
                            <CardUser
                                id={student?.id || 0}
                                firstname={student.firstname}
                                lastname={student.lastname}
                                image={student.image}
                                phone={student.phone}
                                rating={student.rating || 0}
                            />
                        </Box>

                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Группа" />
                            <ListGroupItem
                                id={group.id || 0}
                                icon={group.icon}
                                name={group.name}
                                students={group.students}
                            />
                        </Box>
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Расписание" />
                            <Schedule schedule={schedule || []} />
                        </Box>
                    </Grid2>

                    <Grid2
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 7
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '16px'
                        }}>

                        {/* <Box
                                sx={{
                                    bgcolor: 'background.paper',
                                    border: 1,
                                    borderColor: 'var(--mui-palette-divider)',
                                    borderRadius: 1,
                                }}
                            >
                                <Grid2Header title="Курсы" />
                                <ListCourseItem
                                    id={course.id || 0}
                                    icon={course.icon}
                                    name={course.name}
                                    lessons={course.lessons}
                                />
                            </Box> */}

                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Уроки" />
                            <Lessons grades={grades} />
                        </Box>
                    </Grid2>
                </Grid2>
            </BoxCenterMain>
        </BoxCenterRoot>
    )
}

export default StudentContainer

