import { FC } from "react";

import { Box } from "@mui/material";
import { pageModel } from "shared/ui/page-root";
import { PATH_AVATAR_NRM } from "shared/config";

import { groupModel } from "entities/group";

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import css from './styles.module.scss'

export type TStudentProps = {
    id: number
    firstname: string
    lastname: string
    phone: string
    image: string
    rating: number
}

export const CardUser: FC<TStudentProps> = ({
    id,
    firstname,
    lastname,
    phone,
    image,
    rating
}) => {

    const open = () => {
        groupModel.events.selectGroup(id)
        pageModel.events.setPage('/students')
    }

    return (
        <Box
            sx={{
                bgcolor: 'background.paper',
            }}
            className={css.card}
        >
            <div className={css.header}>

                <div className={css.courseHeader}>
                    <div className={css.icon}>
                        <img
                            width={100}
                            src={!image ? 'https://itansar.ru/assets/user.png' : PATH_AVATAR_NRM + image}
                            alt={firstname}
                        />
                    </div>
                    <div className={css.title}>{`${firstname} ${lastname}`}</div>
                    <div className={css.stat}>

                        {/* <GroupMenu id={id} /> */}

                    </div>
                </div>

            </div>
            <div className={css.main}>
                <div className={css.list}>
                    <div className={css.item}>
                        <div className={css.stack}>
                            <div className={css.icon}><SupervisedUserCircleIcon className="svg-custom" /></div>
                            <div className={css.name}>Номер телефона</div>
                        </div>
                        <div className={css.value}>
                            {phone}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={css.menu}>
                <Button
                    size="medium"
                    variant="outlined"
                    color="primary"
                    startIcon={<OpenInFullIcon />}
                    onClick={open}
                >
                    Изменить
                </Button>
            </div> */}
        </Box>
    )
}