import { FC } from "react";
import { Box, Grid2 } from "@mui/material";
import { AddEmployee } from "features/employee/add";

import Employees from "widgets/employees/ui";
import EmployeeModal from "entities/employee/ui/modal/ui";
import Breadcrumbs from "shared/ui/breadcrumbs/ui";
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { GridTitle } from "shared/ui/grid-title";
import { Grid2Header } from "shared/ui/grid2header";

import BoxCenterRoot from "shared/ui/box-center/root";
import BoxCenterHeader from "shared/ui/box-center/header";
import BoxCenterMain from "shared/ui/box-center/main";

const EmployeesPage: FC = () => {
    return (
        <>
            <EmployeeModal />
            <BoxCenterRoot mw={1100}>
                <BoxCenterHeader>
                    <Breadcrumbs
                        links={[
                            {
                                icon: <HomeIcon sx={{ mr: 0.8 }} fontSize="small" />,
                                name: 'Главная',
                                link: '/home'
                            },
                            {
                                icon: <PeopleAltIcon sx={{ mr: 0.8 }} fontSize="small" />,
                                name: 'Сотрудники',
                                link: '/employees'
                            },
                        ]}
                    />
                </BoxCenterHeader>
                <BoxCenterMain>
                    <GridTitle
                        title="Сотрудники"
                        sub_title="Список всех сотрудников компании"
                    />
                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 12, sm: 12, md: 12, lg: 5 }}
                            sx={{ maxWidth: 450 }}
                        >
                            <Box
                                sx={{
                                    bgcolor: 'background.paper',
                                    border: 1,
                                    borderColor: 'var(--mui-palette-divider)',
                                    borderRadius: 1,
                                }}
                            >
                                <Grid2Header title="Новый сотрудник" />
                                <AddEmployee />
                            </Box>
                        </Grid2>

                        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 7 }}>
                            <Box
                                sx={{
                                    bgcolor: 'background.paper',
                                    border: 1,
                                    borderColor: 'var(--mui-palette-divider)',
                                    borderRadius: 1,
                                }}
                            >
                                <Grid2Header title="Список сотрудников" />
                                <Employees />
                            </Box>
                        </Grid2>

                    </Grid2>

                </BoxCenterMain>
            </BoxCenterRoot>
        </>
    )
}

export default EmployeesPage

