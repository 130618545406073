import { FC, Fragment } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { ListItemButton } from '@mui/material';
import { PATH_AVATAR_MIN } from 'shared/config';
import { studentModel } from 'entities/student';
import { pageModel } from 'shared/ui/page-root';
import { TStudent } from 'shared/api/events/student';

import css from './styles.module.scss'

import { groupModel } from 'entities/group';

type TProps = {
    students: TStudent[]
}

export const RatingList: FC<TProps> = ({ students }) => {

    const groups = groupModel.selectors.useGroups()

    return (
        <List sx={{ width: '100%', p: 0, m: 0 }}>

            {students.length === 0 ?
                <Typography
                    variant="h6"
                    component="h6"
                    sx={{ p: 6, textAlign: 'center' }}
                >
                    Нет учеников
                </Typography> : students.map((student, i) => {

                    console.log('student.id', student.id)

                    const group_name = groups.find(group => group.id === student.group_id)?.name || 'не указана'

                    return (
                        <Fragment key={'k'+student.id}>
                            <ListItem
                                disablePadding
                                sx={{ cursor: 'pointer' }}
                                alignItems="flex-start"
                                onClick={() => {
                                    // studentModel.events.selectStudent(student?.id || 0)
                                    studentModel.events.setSelectStudent(student)
                                    student.id && pageModel.events.setPage('/student')
                                }}
                            >
                                <ListItemButton>
                                    <div className={css.user}>
                                        <div className={css.number}>
                                            <span>{i + 1}</span>
                                        </div>
                                        <div className={css.icon}>
                                            <Avatar alt={`${student.firstname} ${student.lastname}`} src={PATH_AVATAR_MIN + student.image} />
                                        </div>
                                        <div className={css.stack}>
                                            <div className={css.name}>{`${student.firstname} ${student.lastname}`}</div>
                                            <div className={css.info}>
                                                {`Группа ${group_name}`}
                                            </div>
                                        </div>
                                        <div className={css.grade}>
                                            <span>
                                                {student?.rating || 0}
                                            </span>
                                        </div>
                                    </div>
                                </ListItemButton>
                            </ListItem>
                            {students.length - 1 > i && <Divider />}
                        </Fragment>
                    )
                })}
        </List>
    );
}

export default RatingList