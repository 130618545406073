import axios from "axios"

import { API_URL } from "shared/config"
import { TPresent } from "entities/present/model"

export type TReqPresent = {
    id?: number
    title: string
    more: string
    ref: string
    course_id: number
}

export const addPresentAPI = (course: TReqPresent) => {
    const route = API_URL + '/add-present'
    return axios.post(
        route, course, {
        withCredentials: true
    })
}

export const editPresentAPI = (course: TReqPresent) => {
    const route = API_URL + '/edit-present'
    return axios.post(
        route, course, {
        withCredentials: true
    })
}

export const delPresentAPI = (id: number) => {
    const route = API_URL + '/del-present'
    return axios.post(
        route, { id }, {
        withCredentials: true
    })
}

export const getPresentsAPI = (cid: number) => {
    const route = API_URL + '/get-presents'
    return axios.post<TPresent[]>(
        route, { cid }, {
        withCredentials: true
    })
}

export const getPresentRefAPI = (pid: number) => {
    const route = API_URL + '/get-present-ref'
    return axios.post<string | 'no_access'>(
        route, { pid }, {
        withCredentials: true
    })
}