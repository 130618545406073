import { TControlAction } from "@ctypes/model"
import { TControlAPI } from "@ctypes/socket/client-to-server"
import { WS } from "processes/socket"

export const controlAPI = (
    action: TControlAction,
) => {
    const data: TControlAPI = {
        event: 'control',
        payload: {
            action
        }
    }
    WS.sendData(data)
}