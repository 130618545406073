import { FC } from "react";
import { Box, Grid2 } from "@mui/material";

import AddGroup from "features/group/add/ui";
import Groups from "widgets/groups/ui";
import Breadcrumbs from "shared/ui/breadcrumbs/ui";
import HomeIcon from '@mui/icons-material/Home';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

import { GridTitle } from "shared/ui/grid-title";
import { Grid2Header } from "shared/ui/grid2header";

import BoxCenterRoot from "shared/ui/box-center/root";
import BoxCenterHeader from "shared/ui/box-center/header";
import BoxCenterMain from "shared/ui/box-center/main";

const GroupsPage: FC = () => {
    return (
        <BoxCenterRoot mw={1100}>
            <BoxCenterHeader>
                <Breadcrumbs
                    links={[
                        {
                            icon: <HomeIcon sx={{ mr: 0.8 }} fontSize="small" />,
                            name: 'Главная',
                            link: '/home'
                        },
                        {
                            icon: <GroupWorkIcon sx={{ mr: 0.8 }} fontSize="small" />,
                            name: 'Группы',
                            link: '/groups'
                        },
                    ]}
                />
            </BoxCenterHeader>
            <BoxCenterMain>
                <GridTitle
                    title="Группы обучающихся"
                    sub_title="Список всех групп"
                />
                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6, }}>
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Новая группа" />
                            <AddGroup />
                        </Box>
                    </Grid2>

                    <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '16px'
                            }}
                        >
                            <Groups />
                        </Box>
                    </Grid2>
                </Grid2>
            </BoxCenterMain>
        </BoxCenterRoot>
    )
}

export default GroupsPage

