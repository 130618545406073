import { createEvent } from "effector"
import { TPresent } from "entities/present/model"

const addPresent = createEvent<TPresent>()
const editPresent = createEvent<TPresent>()
const delPresent = createEvent<number>()
const setPresents = createEvent<TPresent[]>()

const setPresentRef = createEvent<string | 'no_access' | null>()

export const events = {
    addPresent,
    editPresent,
    delPresent,
    setPresents,
    setPresentRef
}