import axios from "axios"
import { API_URL } from "shared/config"
import { TLesson } from "entities/lesson/model"

export type TReqLesson = {
    id?: number
    group_id: number
    teacher_id: number
    course_id: number
    present_id: number
}

export const startLessonAPI = (lesson: TReqLesson) => {
    const route = API_URL + '/start-lesson'
    return axios.post(
        route, lesson, {
        withCredentials: true
    })
}

export const finishLessonAPI = (id: number) => {
    const route = API_URL + '/finish-lesson'
    return axios.post(
        route, { id }, {
        withCredentials: true
    })
}

// export const delPresentAPI = (id: number) => {
//     const route = API_URL + '/del-present'
//     return axios.post(
//         route, { id }, {
//         withCredentials: true
//     })
// }

export const getLessonAPI = (group_id: number, present_id: number) => {
    const route = API_URL + '/get-lesson'
    return axios.post<TLesson | null>(
        route,
        {
            g: group_id,
            p: present_id
        }, {
        withCredentials: true
    })
}
export const getLessonsAPI = (group_id: number) => {
    const route = API_URL + '/get-lessons'
    return axios.post<TLesson[] | []>(
        route,
        {
            g: group_id
        }, {
        withCredentials: true
    })
}