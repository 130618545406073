import axios from "axios"
import { API_URL } from "shared/config"
import { TUser } from "../events/user"

export const connectAPI = () => {
    const route = API_URL + '/connect'
    return axios.get<TUser>(
        route, {
        withCredentials: true
    })
}

export const logoutAPI = () => {
    const route = API_URL + '/logout'
    return axios.get(
        route, {
        withCredentials: true
    })
}