import type { Navigation } from '@toolpad/core';

import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

const NAVIGATION_ADMIN: Navigation = [
    {
        kind: 'header',
        title: 'Навигатор',
    },
    {
        segment: 'home',
        title: 'Главная',
        icon: <HomeIcon />,
    },
    {
        segment: 'user',
        title: 'Мой профиль',
        icon: <PersonIcon />,
    },
    {
        segment: 'rating',
        title: 'Рейтинг учеников',
        icon: <TrendingUpIcon />,
    },
    {
        kind: 'divider',
    },
    {
        kind: 'header',
        title: 'Администрирование',
    },
    {
        segment: 'employees',
        title: 'Сотрудники',
        icon: <PeopleAltIcon />,
    },
    {
        segment: 'admin/courses',
        title: 'Курсы',
        icon: <AutoStoriesIcon />,
    },
    {
        segment: 'groups',
        title: 'Группы',
        icon: <GroupWorkIcon />,
    },
    {
        segment: 'students',
        title: 'Ученики',
        icon: <GroupsIcon />,
    },
    // {
    //     segment: 'courses',
    //     title: 'Курсы',
    //     icon: <AutoStoriesIcon />,
    //     children: [
    //         {
    //             segment: 'sales',
    //             title: 'JavaScript. Основы',
    //             icon: <GroupsIcon />,
    //         },
    //         {
    //             segment: 'traffic',
    //             title: 'Python. Погружение',
    //             icon: <GroupsIcon />,
    //             children: [
    //                 {
    //                     segment: 'sales',
    //                     title: 'Python 1',
    //                     icon: <ImportContactsIcon />,
    //                 },
    //                 {
    //                     segment: 'traffic',
    //                     title: 'Python 2',
    //                     icon: <ImportContactsIcon />,
    //                 },
    //                 {
    //                     segment: 'add',
    //                     title: 'Добавить урок',
    //                     icon: <AddIcon />,
    //                 },
    //             ],
    //         },
    //         {
    //             segment: 'add',
    //             title: 'Добавить курс',
    //             icon: <AddIcon />,
    //         },
    //     ],
    // },
];

export default NAVIGATION_ADMIN