import { FC, useEffect } from "react";
import { Avatar, Box, Grid2, Typography } from "@mui/material";

import { PDF } from "shared/ui/pdf/ui";
import { courseModel } from "entities/course";
import { presentAPI } from "shared/api/events";
import { presentModel } from "entities/present";
import { getPresentRefAPI } from "shared/api/requests/present";

import BoxCenterRoot from "shared/ui/box-center/root";
import BoxCenterMain from "shared/ui/box-center/main";
import BoxCenterHeader from "shared/ui/box-center/header";

import HomeIcon from '@mui/icons-material/Home';
import Breadcrumbs from "shared/ui/breadcrumbs/ui";
import PresentMenu from "entities/present/ui/menu/ui";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

const PresentPage: FC = () => {

    const present = presentModel.selectors.useSelectPresent()
    const course = courseModel.selectors.useSelectCourse()

    const presentRef = presentModel.selectors.usePresentRef()

    useEffect(() => {
        present?.id && getPresentRefAPI(present.id)
            .then(ref => {
                presentAPI.events.setPresentRef(ref.data)
            })
        return () => {
            presentAPI.events.setPresentRef(null)
        }
    }, [present])

    return (
        <BoxCenterRoot mw={1100}>
            <BoxCenterHeader>
                <Breadcrumbs
                    links={[
                        {
                            icon: <HomeIcon sx={{ mr: 0.8 }} fontSize="small" />,
                            name: 'Главная',
                            link: '/home'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: 'Курсы',
                            link: '/admin/courses'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: course?.name || 'Курс',
                            link: '/presents'
                        },
                        {
                            icon: <ImportContactsIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: present?.title || 'Презентация',
                            link: '/presents'
                        },
                    ]}
                />
            </BoxCenterHeader>
            <BoxCenterMain>
                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, }}>
                        {presentRef ? <PDF
                            load={true}
                            url={`https://itansar.ru/php-server/presents/${presentRef}`}
                        /> : ''}
                        <Box
                            sx={{
                                mt: 0,
                                mb: 2,
                                p: 2,
                                border: 1,
                                borderRadius: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                bgcolor: 'background.paper',
                                color: 'var(--mui-palette-text-secondary)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                            component="div"
                        >
                            <Avatar sx={{}}>
                                <ImportContactsIcon />
                            </Avatar>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'var(--mui-palette-text-secondary)',
                                    paddingRight: 1
                                }}
                                variant="subtitle1"
                                component="div"
                            >
                                {present?.title}
                            </Typography>
                            <PresentMenu id={present?.id || 0} />
                        </Box>
                    </Grid2>
                </Grid2>
            </BoxCenterMain>
        </BoxCenterRoot>
    )
}

export default PresentPage

