import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { Box, Card, CardContent, CardMedia, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { PATH_AVATAR_BIG } from 'shared/config';
import { employeeModel } from 'entities/employee';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import WorkIcon from '@mui/icons-material/Work';

export default function EmployeeModal() {

    const open = employeeModel.selectors.useOpenModal()

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

    const handleClickOpen = () => {
        employeeModel.events.openModal(true);
    };

    const handleClose = () => {
        employeeModel.events.openModal(false);
    };

    const user = employeeModel.selectors.useSelectEmployee()

    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>О сотруднике</DialogTitle>
                <DialogContent>

                    <Card sx={{ maxWidth: '100%', boxShadow: 'none' }}>
                        <CardMedia
                            sx={{ height: 260, maxHeight: 340 }}
                            image={PATH_AVATAR_BIG + user?.image}
                            title={user?.firstname}
                        />
                        <CardContent sx={{ p: 0, paddingTop: '24px' }}>
                            <Typography gutterBottom variant="h5" component="div">
                                {`${user?.firstname} ${user?.lastname}`}
                            </Typography>

                            <Box sx={{ p: 0, m: 0, bgcolor: 'background.paper' }}>
                                <List>
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ pl: 0 }}>
                                            <ListItemIcon sx={{ minWidth: '46px' }}>
                                                <WorkIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={user?.job} />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ pl: 0, ml: 0 }}>
                                            <ListItemIcon sx={{ minWidth: '46px' }}>
                                                <PhoneAndroidIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={user?.phone} />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ pl: 0, ml: 0 }}>
                                            <ListItemIcon sx={{ minWidth: '46px' }}>
                                                <AlternateEmailIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={user?.email} />
                                        </ListItemButton>
                                    </ListItem>

                                </List>
                            </Box>
                            {/* <Typography gutterBottom variant="subtitle1" component="div">
                                {`${user?.phone} | ${user?.email}`}
                            </Typography> */}
                        </CardContent>
                    </Card>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Закрыть</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}