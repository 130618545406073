import { Box, Button, MobileStepper, Stack, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import css from './styles.module.scss'
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import CustomizedProgressBars from '../progress/ui';
import { IconT } from 'shared/assets/icons';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { paginationModel } from '.';
import { controlAPI } from 'shared/api/control';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

export function PDF({ url, load }: { url: string, load: boolean }) {
    // const [loader, setLoader] = useState(false)

    const numPages = paginationModel.selectors.useNumPages()
    const pageNumber = paginationModel.selectors.usePage()

    async function onDocumentLoadSuccess(page: PDFDocumentProxy): Promise<void> {
        // setNumPages(page.numPages);
        paginationModel.events.setNumPages(page?.numPages || 0);
        const size = await page.getPage(page.numPages)
        console.log("size", size.view)
    }

    const divBlock = useRef<HTMLDivElement>(null);

    const [width, setWidth] = useState(0)

    useEffect(() => {

        const resize = () => {
            const _width = divBlock.current?.offsetWidth
            setWidth(_width || 0)
        }

        resize()

        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize);
        };

    }, [])

    const pageEvent = function (e: KeyboardEvent) {
        console.log('e.key ', e.key)
        if (e.key === "ArrowRight" || e.key === "ArrowUp") paginationModel.events.setPage('next')
        else if (e.key === "ArrowLeft" || e.key === "ArrowDown") paginationModel.events.setPage('prev')
    }

    useEffect(() => {
        window.addEventListener("keydown", pageEvent)
        return () => {
            window.removeEventListener("keydown", pageEvent)
        }
    }, [])

    const theme = useTheme();

    return (
        <>
            <Box className={css.container} ref={divBlock} sx={{ borderRadius: 1, overflow: 'hidden' }}>
                {!url ? (load ? <CustomizedProgressBars /> : <IconT width={84} height={84}  className="svg-custom" />) : ''}
                {url && <Document
                    file={url}
                    error=""
                    loading={<CustomizedProgressBars />}
                    onLoadSuccess={(value) => onDocumentLoadSuccess(value)}
                >
                    <Page
                        canvasBackground='black'
                        pageNumber={pageNumber}
                        className={css.page}
                        width={width}
                        loading={<CustomizedProgressBars />}
                        error={'Ошибка загрузки...'}
                        noData="Страница не найдена"
                    />
                </Document>}
            </Box>
            <Stack sx={{ p: 1, alignItems: 'center' }}>
                {/* <Pagination boundaryCount={1} count={numPages} onChange={(e, value) => setPageNumber(value)} /> */}
                <MobileStepper
                    variant="text"
                    steps={numPages || 1}
                    position="static"
                    activeStep={pageNumber - 1}
                    nextButton={
                        <Button
                            size="small"
                            onClick={() => {
                                paginationModel.events.setPage('next')
                                controlAPI('next')
                            }}
                            disabled={pageNumber === Number(numPages)}
                        >
                            След
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={() => {
                                paginationModel.events.setPage('prev')
                                controlAPI('prev')
                            }}
                            disabled={pageNumber - 1 === 0}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Пред
                        </Button>
                    }
                />
            </Stack>
        </>
    );
}