import { useStore } from "effector-react"
import { createEffect, createEvent, createStore, sample } from "effector"
import { TStudent } from "shared/api/events/student"
import { studentModel } from "entities/student"

const editStudent = createEvent<number>()

export const $editStudentStrore = createStore<TStudent | null>(null)

type TSelectStudents = {
    id: number
    students: TStudent[]
}

const selectStudentsFx = createEffect(({ id, students }: TSelectStudents): TStudent | null => {
    if (id === null) return null
    return students.filter(std => std.id === id)[0]
})

sample({
    clock: editStudent,
    source: studentModel.$studentsStrore,
    filter: (s: TStudent[] | null): s is TStudent[] => s !== null,
    fn: (students, id) => ({ students, id }),
    target: selectStudentsFx
})

sample({
    clock: selectStudentsFx.doneData,
    target: $editStudentStrore
})

const useEditStudent = () => useStore($editStudentStrore)

export const selectors = {
    useEditStudent,
}

export const events = {
    editStudent
}