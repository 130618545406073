import * as React from 'react';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';

import dayjs from 'dayjs';

type TProps = {
    value: number
    setValue: (value: any) => void
}
export default function DigitalClock({ value, setValue }: TProps) {
    
    console.log('DigitalClock value', value)

    const [d, setD] = React.useState<Date>()

    // React.useEffect(() => {
    //     handleChange(v as Date)
    //     console.log('DigitalClock useEffect v', v)
    // }, [v])

    const handleChange = (v: Date) => {
        const unix = dayjs(v).unix() * 1000
        const date = new Date(v || 0)
        const day_js = dayjs(date)

        console.log('...handleChange v', v)
        console.log('...handleChange unix', unix)
        console.log('...handleChange date', date)
        console.log('...handleChange day_js', day_js)

        setValue(unix)
        setD(date)
    }

    React.useEffect(() => {
        const date = new Date(value || 0)
        // const day_js = dayjs(date)
        setD(date)
    }, [])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem label="Начало урока">
                <MultiSectionDigitalClock
                    timezone='system'
                    value={dayjs(d)}
                    timeSteps={{ hours: 1, minutes: 5 }}

                    onChange={v => {
                        console.log('onChange v', v)
                        handleChange(v)
                    }}
                />
            </DemoItem>
        </LocalizationProvider>
    );
}