type TDay = 1 | 2 | 3 | 4 | 5 | 6 | 7

export type TTrainingDays = {
    [k: number]: string
}

export const training_days: TTrainingDays = {
    1: 'Понедельник',
    2: 'Вторник',
    3: 'Среда',
    4: 'Четверг',
    5: 'Пятница',
    6: 'Суббота',
    7: 'Воскресенье',
}

export const training_short_days: TTrainingDays = {
    1: 'ПН',
    2: 'ВТ',
    3: 'СР',
    4: 'ЧТ',
    5: 'ПТ',
    6: 'СБ',
    7: 'ВС',
}