import { FC } from "react";

import LabelIcon from '@mui/icons-material/Label';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import { Button, ListItemIcon, Typography } from "@mui/material";
import { TPresent } from "entities/present/model";
import { finishLessonAPI, startLessonAPI } from "shared/api/requests/lesson";

import { TGroup } from "shared/api/events/group";
import { lessonAPI } from "shared/api/events";
import { lessonModel } from "entities/lesson";

import { userModel } from "entities/user";

import css from './styles.module.scss'

type TActionProps = {
    group: TGroup
    present: TPresent
}

export const LessonAction: FC<TActionProps> = ({ present, group }) => {

    const user = userModel.selectors.useUser()

    return (
        <div className={css.lesson}>
            <div className={css.stack}>
                <ListItemIcon sx={{ minWidth: '24px' }}>
                    <LabelIcon />
                </ListItemIcon>
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        color: 'var(--mui-palette-text-secondary)'
                    }}
                    variant="subtitle1"
                    component="div"
                >
                    {present?.title}
                </Typography>
            </div>

            {user?.role === 'employee' && group.teacher === user.user.id ?
                <ActionButtonEmployee
                    group={group}
                    present={present}
                /> :
                <ActionButtonStudent />}

        </div>
    )
}


const ActionButtonStudent: FC = () => {

    const lesson = lessonModel.selectors.useLesson()

    if (!lesson) return (
        <Button
            size="large"
            variant="text"
            color="primary"
            onClick={() => { }}
            startIcon={<DoneAllIcon />}
        >
            Урок не пройден
        </Button>
    )

    if (lesson?.status === 0) return (
        <Button
            size="large"
            variant="text"
            color="error"
            onClick={() => { }}
            startIcon={<DoneAllIcon />}
        >
            Урок идёт
        </Button>
    )

    return (
        <Button
            size="large"
            variant="text"
            color="success"
            onClick={() => { }}
            startIcon={<DoneAllIcon />}
        >
            Урок завершён
        </Button>
    )
}

const ActionButtonEmployee: FC<TActionProps> = ({ group, present }) => {

    const lesson = lessonModel.selectors.useLesson()

    const startLesson = () => {
        if (!group?.id) return

        startLessonAPI({
            group_id: group.id,
            teacher_id: group.teacher,
            course_id: group.course,
            present_id: present.id,
        })
            .then(lesson => {
                lessonAPI.events.startLesson(lesson.data)
            })
    }

    const finishLesson = () => {
        if (!lesson?.id) return

        finishLessonAPI(lesson.id)
            .then(lesson => {
                lessonAPI.events.finishLesson()
            })
    }


    if (!lesson) return (
        <Button
            size="large"
            variant="outlined"
            color="primary"
            onClick={startLesson}
            startIcon={<DoneAllIcon />}
        >
            Начать урок
        </Button>
    )

    if (lesson?.status === 0) return (
        <Button
            size="large"
            variant="outlined"
            color="error"
            onClick={finishLesson}
            startIcon={<DoneAllIcon />}
        >
            Завершить урок
        </Button>
    )

    return (
        <Button
            size="large"
            variant="outlined"
            color="success"
            onClick={() => { }}
            startIcon={<DoneAllIcon />}
        >
            Урок завершён
        </Button>
    )
}