import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { groupAPI } from 'shared/api/events';
import { snackbarModel } from 'shared/ui/snackbar';
import { groupModel } from 'entities/group';
import { featureGroup } from 'features/group';
import { alertModel } from 'shared/ui/alert';
import { delGroupAPI } from 'shared/api/requests/group';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));


export default function GroupMenu(props: { id: number }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log('handleClick')
    event.stopPropagation()
    groupModel.events.selectGroup(props.id)
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    console.log('handleClose')
    setAnchorEl(null);
    groupModel.events.openModal(false)
    groupModel.events.selectGroup(0)
  };

  const groupId = groupModel.selectors.useSelectGroup()?.id || 0

  console.log('groupId', groupId)

  const deleteGroup = (e: React.FormEvent) => {

    alertModel.events.setAlert({
      status: true,
      title: 'Удалить данную группу?',
      text: 'Данное действие нельзя отменить. Вы уверены, что хотите удалить эту группу?',
      action: {
        success: {
          color: 'error',
          _click: () => delGroupAPI(groupId)
            .then(response => {
              console.log('response?.data', response?.data)
              response?.data && groupAPI.events.delGroup(groupId)
              groupModel.events.selectGroup(0)
              alertModel.events.setAlert(null);
              snackbarModel.events.setSnackbar({
                text: 'Группа успешно удалена!',
                severity: 'success',
                status: true
              })
              // navigate('/learn/lessons')
            })
            .catch(function (e) {
              // setDenied(true)
            }),
          text: 'Удалить'
        },
        cancel: {
          _click: () => { },
          text: 'Отмена'
        }
      }
    })

    setAnchorEl(null);
    e.preventDefault()

  }

  const editGroup = (e: React.FormEvent) => {
    setAnchorEl(null);
    e.preventDefault()
    featureGroup.events.editGroup(groupId)
  }

  return (
    <div>
      <IconButton
        sx={{ m: -1, p: 1 }}
        // aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        edge="end"
        aria-label="comments"
        onClick={handleClick}
      >
        <SettingsIcon />
      </IconButton>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={editGroup} disableRipple>
          <EditIcon />
          Редактировать
        </MenuItem>
        <MenuItem onClick={deleteGroup} disableRipple>
          <DeleteIcon />
          Удалить
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={() => groupModel.events.openModal(true)} disableRipple>
          <OpenInFullIcon />
          Открыть
        </MenuItem>
      </StyledMenu>
    </div>
  );
}