import { createEvent } from "effector"

export type TGroup = {
    id?: number
    icon: string
    name: string
    course: number
    teacher: number
    training: [number, number][]
    students: number
}

const addGroup = createEvent<TGroup>()
const editGroup = createEvent<TGroup>()
const delGroup = createEvent<number>()
const setGroups = createEvent<TGroup[]>()

const setSelectGroup = createEvent<TGroup | null>()

export const events = {
    addGroup,
    editGroup,
    delGroup,
    setGroups,
    setSelectGroup
}