import { useStore } from "effector-react"
import { createEffect, createEvent, createStore, sample } from "effector"
import { courseModel } from "entities/course"
import { TCourse } from "entities/course/model"

const editCourse = createEvent<number>()

export const $editCourseStrore = createStore<TCourse | null>(null)

type TSelectCourses = {
    id: number
    courses: TCourse[]
}

const selectCoursesFx = createEffect(({ id, courses }: TSelectCourses): TCourse | null => {
    if (id === null) return null
    return courses.filter(c => c.id === id)[0]
})

sample({
    clock: editCourse,
    source: courseModel.$coursesStrore,
    fn: (courses, id) => ({ courses, id }),
    target: selectCoursesFx
})

sample({
    clock: selectCoursesFx.doneData,
    target: $editCourseStrore
})

const useEditCourse = () => useStore($editCourseStrore)

export const selectors = {
    useEditCourse,
}

export const events = {
    editCourse
}