import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { snackbarModel } from '.';

export const SnackbarForm: React.FC = () => {
    //   const [open, setOpen] = React.useState(false);

    const snackbar = snackbarModel.selectors.useSnackbar()

    // const handleClick = () => {
    //     setOpen(true);
    // };

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        snackbarModel.events.setSnackbar(null);
    };

    return (
        <Snackbar open={snackbar?.status || false} autoHideDuration={6000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={snackbar?.severity}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {snackbar?.text}
            </Alert>
        </Snackbar>
    );
}