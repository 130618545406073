import { FC, useEffect, useState } from "react";
import { Box, Grid2 } from "@mui/material";

import { Grid2Header } from "shared/ui/grid2header";

import HomeIcon from '@mui/icons-material/Home';
import Breadcrumbs from "shared/ui/breadcrumbs/ui";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import { groupModel } from "entities/group";
import { getStudentsAPI } from "shared/api/requests/student";
import { lessonAPI, presentAPI, studentAPI } from "shared/api/events";
import { Schedule } from "widgets/group/schedule";
import { courseModel } from "entities/course";

import Students from "widgets/students/ui";
import Lessons from "widgets/group/lessons/ui";
import CardGroup from "entities/group/ui/card/ui";

import { getPresentsAPI } from "shared/api/requests/present";
import { getLessonsAPI } from "shared/api/requests/lesson";
import BoxCenterRoot from "shared/ui/box-center/root";
import BoxCenterHeader from "shared/ui/box-center/header";
import BoxCenterMain from "shared/ui/box-center/main";

type Lesson = {
    day: number
    course: string
    time: number
}

const GroupPage: FC = () => {

    const group = groupModel.selectors.useSelectGroup()
    const courses = courseModel.selectors.useCourses()

    useEffect(() => {
        group?.id && getStudentsAPI(group.id)
            .then(stds => {
                studentAPI.events.setStudents(stds.data)
            })
    }, [group])

    useEffect(() => {
        group?.course && getPresentsAPI(group.course)
            .then(presents => {
                presentAPI.events.setPresents(presents.data)
            })
    }, [group])

    useEffect(() => {
        group?.id && getLessonsAPI(group.id)
            .then(lessons => {
                lessonAPI.events.setLessons(lessons.data)
            })
    }, [group])

    const [lessons, setLessons] = useState<Lesson[]>([])

    useEffect(() => {

        const groupCourse = courses.find(course => course.id === group?.course)

        if (!groupCourse?.id) return

        courseModel.events.selectCourse(groupCourse.id)

        const _lessons: Lesson[] = []

        group?.training?.forEach(([day, time]) => {
            _lessons.push({
                day,
                course: groupCourse.name,
                time
            })
        });

        setLessons(_lessons)
    }, [])

    if (!group) return <></>

    return (
        <BoxCenterRoot mw={1100}>
            <BoxCenterHeader>
                <Breadcrumbs
                    links={[
                        {
                            icon: <HomeIcon sx={{ mr: 0.8 }} fontSize="small" />,
                            name: 'Главная',
                            link: '/home'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: 'Группы',
                            link: '/groups'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: `${group?.name}`,
                            link: '/group'
                        },
                    ]}
                />
            </BoxCenterHeader>
            <BoxCenterMain>
                <Grid2 container spacing={2}>

                    <Grid2
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 5.5,
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '16px'
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="О группе" />
                            <CardGroup
                                id={group.id || 0}
                                icon={group.icon}
                                name={group.name}
                                course={group.course}
                                training={group.training}
                                students={group.students}
                            />
                        </Box>
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Расписание группы" />
                            <Schedule lessons={lessons} />
                        </Box>
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Ученики группы" />
                            <Students />
                        </Box>
                    </Grid2>

                    <Grid2
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 6.5
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '16px'
                        }}
                    >

                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Уроки группы" />
                            <Lessons />
                        </Box>
                    </Grid2>
                </Grid2>
            </BoxCenterMain>
        </BoxCenterRoot>
    )
}

export default GroupPage

