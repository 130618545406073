import * as React from 'react';
import Button from '@mui/material/Button';

import { snackbarModel } from 'shared/ui/snackbar';
import { Box, Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

import { addCourseAPI, editCourseAPI } from 'shared/api/requests/course';
import { courseAPI } from 'shared/api/events';
import { featureCourse } from '..';

import { getCookie } from 'shared/lib/get-cookie';
import { getFormData } from 'shared/lib/get-form-data';

import { PATH_COURSE_NRM } from 'shared/config';
import { IconLoader, IconPlus } from 'shared/assets/icons';

import css from './styles.module.scss'

type TValue = {
    value: string
    status: null | 'error' | 'success'
}

export default function AddCourse() {

    const editCourse = featureCourse.selectors.useEditCourse()

    const default_value = {
        value: '',
        status: null
    }

    React.useEffect(() => {
        setCourseImage({
            img: editCourse?.icon || '',
            hash: '',
            load: 0
        })
        setName({
            value: editCourse?.name || '',
            status: null
        })
        setMore({
            value: editCourse?.more || '',
            status: null
        })
        setLang({
            value: editCourse?.lang || '',
            status: null
        })

    }, [editCourse])

    const [courseImage, setCourseImage] = React.useState({
        img: '',
        hash: '',
        load: 0
    });

    const [name, setName] = React.useState<TValue>(default_value);
    const [more, setMore] = React.useState<TValue>(default_value);
    const [lang, setLang] = React.useState<{
        value: '' | 1 | 2
        status: null | 'error' | 'success'
    }>({
        value: '',
        status: null
    });

    const checkName = (value: string) => {
        setName({
            value,
            status: null
        })
    }

    const checkMore = (value: string) => {
        setMore({
            value,
            status: null
        })
    }
    const checkLang = (value: 1 | 2) => {
        setLang({
            value,
            status: null
        })
    }

    const reset = () => {
        setName(default_value);
        setMore(default_value);
        setCourseImage({
            img: '',
            hash: '',
            load: 0
        });
    }

    function checkForm(): boolean {

        const isName = name.value.trim().length > 0
        const isMore = more.value.trim().length > 0
        const isLang = lang.value === 1 || lang.value === 2

        if (!isName) setName({ ...name, status: 'error' })
        else setName({ ...name, status: 'success' })

        if (!isMore) setMore({ ...more, status: 'error' })
        else setMore({ ...more, status: 'success' })

        if (!isLang) setLang({ ...lang, status: 'error' })
        else setLang({ ...lang, status: 'success' })

        return true &&
            isName &&
            isMore &&
            isLang

    }

    const _addCourse = (e: React.FormEvent) => {
        e.preventDefault()

        if (!checkForm()) {
            snackbarModel.events.setSnackbar({
                text: 'Заполните все поля!',
                severity: 'error',
                status: true
            })
            return
        }

        addCourseAPI({
            icon: courseImage.img,
            name: name.value,
            more: more.value,
            lessons: 0,
            lang: lang.value
        })
            .then(response => {
                snackbarModel.events.setSnackbar({
                    text: 'Сотрудник успешно добавлен!',
                    severity: 'success',
                    status: true
                })
                response?.data?.id && courseAPI.events.addCourse(response.data)
                reset()
            })
            .catch(function (e) { })
    }

    const _editCourse = (e: React.FormEvent) => {
        if (!editCourse?.id) return
        e.preventDefault()
        if (!checkForm()) {
            snackbarModel.events.setSnackbar({
                text: 'Заполните все поля!',
                severity: 'error',
                status: true
            })
            return
        }
        editCourseAPI({
            id: editCourse?.id,
            icon: courseImage.img,
            name: name.value,
            more: more.value,
            lessons: 0,
            lang: lang.value
        })
            .then(response => {
                snackbarModel.events.setSnackbar({
                    text: 'Данные сотрудника успешно изменены!',
                    severity: 'success',
                    status: true
                })
                response?.data?.id && courseAPI.events.editCourse(response.data)
                reset()
            })
            .catch(function (e) { })
    }

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) return

        let img_file: File = e.target.files[0]
        let size_file = img_file.size / 1024 / 1024;

        if (size_file > 15) {
            //
        } else {
            if (img_file) {
                setCourseImage({
                    img: '',
                    hash: '',
                    load: 1
                });
                uploadImage(img_file);
            }
        }
    }


    const uploadImage = (file: File) => {
        console.log('uploadImage oauth', getCookie('oauth'))

        fetch('https://itansar.ru/php-server/api/upload-course.php', {
            method: 'POST',
            body: getFormData({
                file,
                oauth: JSON.stringify({
                    token: getCookie('oauth')
                })
            })
        })
            .then(response => {
                return response.json()
            })

            .then(result => {
                console.log('result', result)
                if (result.res === "ok") {
                    setCourseImage({
                        img: result.data.filename,
                        hash: result.data.hash,
                        load: 0
                    })
                } else {

                    setCourseImage({
                        img: '',
                        hash: '',
                        load: 0
                    })

                    if (result.data.code === 2) {
                    } else if (result.data.code === 3) {
                    } else if (result.data.code === 5) {
                    } else {
                    }
                }

            })
            .catch(e => {
                console.log('catche', e)
                setCourseImage({
                    img: '',
                    hash: '',
                    load: 0
                });
            });

    }

    return (
        <Container sx={{pb: 2, pt: 1}}>
            <form onSubmit={editCourse ? _editCourse : _addCourse}>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        gridGap: '16px',
                        mb: 2, mt: 2
                    }}
                >
                    <div className={`${css.group} ${css.flex}`}>
                        <div className={`${css.icon} ${courseImage.img ? ' ' + css.active : ''}`}>
                            {courseImage.load === 1 ?
                                <div className={css.loader}>
                                    <IconLoader className="svg-custom svg-loader" fill="#836EAD" />
                                </div> :
                                courseImage.img === '' ?
                                    <IconPlus width={34} height={34} fill="#0000001f"  className="svg-custom"/> :
                                    <img
                                        src={PATH_COURSE_NRM + courseImage.img}
                                        className="cc_image"
                                        alt='<>'
                                    />}
                            <input
                                type='file'
                                onChange={(e) => onSelectFile(e)}
                                accept=".jpg, .jpeg, .png"
                            />
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>

                        <FormControl fullWidth>
                            <TextField
                                id="name"
                                label="Название курса"
                                value={name.value}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    checkName(event.currentTarget.value)
                                }}
                                variant="filled"
                                fullWidth
                                error={name.status === 'error'}
                            />

                        </FormControl>

                        <FormControl variant="filled" fullWidth margin="normal">
                            <InputLabel id="lang-label">Язык программирования</InputLabel>
                            <Select
                                id="lang"
                                labelId="lang-label"
                                label="Язык программирования"
                                value={String(lang.value)}
                                onChange={(event: SelectChangeEvent) => {
                                    checkLang(event.target.value as unknown as 1 | 2)
                                }}
                                variant="filled"
                                fullWidth
                                error={lang.status === 'error'}
                            >
                                <MenuItem value={1}>Python</MenuItem>
                                <MenuItem value={2}>JavaScript</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Box>

                <FormControl fullWidth>
                    <TextField
                        id="more"
                        label="Описание курса"
                        value={more.value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            checkMore(event.currentTarget.value)
                        }}
                        multiline
                        rows={4}
                        variant="filled"
                        fullWidth
                        error={more.status === 'error'}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    margin="normal"
                >
                    <Button size="large" variant="contained" fullWidth onClick={editCourse ? _editCourse : _addCourse}>
                        {editCourse ? 'Изменить курс' : 'Создать курс'}
                    </Button>
                </FormControl>

            </form>

        </Container>
    );
}