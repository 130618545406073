import { useStore } from "effector-react"
import { employeeModel } from "entities/employee"
import { TEmployee } from "shared/api/events/employee"
import { createEffect, createEvent, createStore, sample } from "effector"

const editEmployee = createEvent<number>()

export const $editEmployeeStrore = createStore<TEmployee | null>(null)

type TSelectEmployees = {
    id: number
    employees: TEmployee[]
}

const selectEmployeesFx = createEffect(({ id, employees }: TSelectEmployees): TEmployee | null => {
    if (id === null) return null
    return employees.filter(emp => emp.id === id)[0]
})

sample({
    clock: editEmployee,
    source: employeeModel.$employeesStrore,
    fn: (employees, id) => ({ employees, id }),
    target: selectEmployeesFx
})

sample({
    clock: selectEmployeesFx.doneData,
    target: $editEmployeeStrore
})

const useEditEmployee = () => useStore($editEmployeeStrore)

export const selectors = {
    useEditEmployee,
}

export const events = {
    editEmployee
}