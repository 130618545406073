import { useEffect } from "react"
import { useSocket } from "shared/api/socket/model"

export const useApp = () => {
    const socketStatus = useSocket()

    useEffect(() => {

    }, [])

    return {
        socketStatus
    }
}