import { FC } from "react";

import css from './styles.module.scss'
import { Box } from "@mui/material";

import { pageModel } from "shared/ui/page-root";
import { PATH_AVATAR_NRM } from "shared/config";

// import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { groupModel } from "entities/group";

import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

// type TGroupProps = {
//     id: number
//     firstname: string
//     lastname: string
//     name: string
//     course: number
//     training: number[]
//     students: number
// }

export type TEmployeeProps = {
    id: number
    firstname: string
    lastname: string
    email: string
    phone: string
    job: string
    image: string
}

export const CardUser: FC<TEmployeeProps> = ({
    id,
    firstname,
    lastname,
    email,
    phone,
    job,
    image
}) => {

    const open = () => {
        groupModel.events.selectGroup(id)
        pageModel.events.setPage('/students')
    }

    return (
        <Box
            sx={{
                bgcolor: 'background.paper',
            }}
            className={css.card}
        >
            <div className={css.header}>

                <div className={css.courseHeader}>
                    <div className={css.icon}>
                        <img
                            width={100}
                            src={!image ? 'https://itansar.ru/assets/user.png' : PATH_AVATAR_NRM + image}
                            alt={firstname}
                        />
                    </div>
                    <div className={css.title}>{`${firstname} ${lastname}`}</div>
                    <div className={css.stat}>

                        {/* <GroupMenu id={id} /> */}

                    </div>
                </div>

            </div>
            <div className={css.main}>
                <div className={css.list}>
                    <div className={css.item}>
                        <div className={css.stack}>
                            <div className={css.icon}><AutoStoriesIcon className="svg-custom" /></div>
                            <div className={css.name}>Электронная почта</div>
                        </div>
                        <div className={css.value}>
                            {email}
                        </div>
                    </div>
                    <div className={css.item}>
                        <div className={css.stack}>
                            <div className={css.icon}><SupervisedUserCircleIcon className="svg-custom" /></div>
                            <div className={css.name}>Номер телефона</div>
                        </div>
                        <div className={css.value}>
                            {phone}
                        </div>
                    </div>
                    <div className={css.item}>
                        <div className={css.stack}>
                            <div className={css.icon}><CalendarMonthIcon className="svg-custom" /></div>
                            <div className={css.name}>Должность</div>
                        </div>
                        <div className={css.value}>
                            {job}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={css.menu}>
                <Button
                    size="medium"
                    variant="outlined"
                    color="primary"
                    startIcon={<OpenInFullIcon />}
                    onClick={open}
                >
                    Изменить
                </Button>
            </div> */}
        </Box>
    )
}