
import Typography from '@mui/material/Typography';

import { Box } from '@mui/material';

import { groupModel } from 'entities/group';
import { CardItemGroup } from 'entities/group/ui/card-item';
import { employeeModel } from 'entities/employee';

export default function Groups() {

    const groups = groupModel.selectors.useGroups()
    const employee = employeeModel.selectors.useEmployees()

    return (
        <>
            {groups.length === 0 ?
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        border: 1,
                        borderRadius: 1,
                        borderColor: 'var(--mui-palette-divider)',
                    }}
                >
                    <Typography variant="h6" component="h6" sx={{ pt: 12, pb: 12, textAlign: 'center' }}>
                        Ещё нет групп
                    </Typography>
                </Box> : groups.map((group, i) => {
                    const teacher = employee.find(emp => emp.id === group.teacher)
                    const _teacher = teacher ? `${teacher?.firstname} ${teacher?.lastname}` : null
                    return (
                        <>
                            <CardItemGroup
                                id={group.id || 0}
                                icon={group.icon}
                                name={group.name}
                                course={group.course}
                                training={group.training}
                                students={group.students}
                                teacher={_teacher}
                            />
                        </>
                    )
                })}
        </>
    );
}