// import React from 'react'
import ReactDOM from 'react-dom/client'

import 'index.css'
// import App from 'app/-index';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';

// import reportWebVitals from './reportWebVitals'

import App from 'app';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    // <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
