import { FC, useEffect } from "react";
import { Box, Grid2 } from "@mui/material";

import Students from "widgets/students/ui";
import EmployeeModal from "entities/employee/ui/modal/ui";

import { AddStudent } from "features/student/add";
import { studentAPI } from "shared/api/events";
import { getStudentsAPI } from "shared/api/requests/student";

import Breadcrumbs from "shared/ui/breadcrumbs/ui";
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

import { groupModel } from "entities/group";
import { GridTitle } from "shared/ui/grid-title";
import { Grid2Header } from "shared/ui/grid2header";
import BoxCenterRoot from "shared/ui/box-center/root";
import BoxCenterHeader from "shared/ui/box-center/header";
import BoxCenterMain from "shared/ui/box-center/main";

const StudendsPage: FC = () => {

    const group = groupModel.selectors.useSelectGroup()

    useEffect(() => {
        group?.id && getStudentsAPI(group.id)
            .then(stds => {
                studentAPI.events.setStudents(stds.data)
            })
    }, [group])

    return (
        <>
            <EmployeeModal />
            <BoxCenterRoot mw={1100}>
                <BoxCenterHeader>
                    <Breadcrumbs
                        links={[
                            {
                                icon: <HomeIcon sx={{ mr: 0.8 }} fontSize="small" />,
                                name: 'Главная',
                                link: '/home'
                            },
                            {
                                icon: <GroupWorkIcon sx={{ mr: 0.8 }} fontSize="small" />,
                                name: 'Группы',
                                link: '/groups'
                            },
                            {
                                icon: <GroupsIcon sx={{ mr: 0.8 }} fontSize="small" />,
                                name: group?.name || 'Ученики',
                                link: '/students'
                            },
                        ]}
                    />
                </BoxCenterHeader>
                <BoxCenterMain>

                    <GridTitle
                        title={group ? `Группа ${group?.name}` : 'Все ученики'}
                        sub_title={group ? `Список учеников группы ${group?.name}` : "Список всех учеников компании"}
                    />

                    <Grid2 container spacing={2}>

                        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 5, }}>
                            <Box
                                sx={{
                                    bgcolor: 'background.paper',
                                    border: 1,
                                    borderColor: 'var(--mui-palette-divider)',
                                    borderRadius: 1,
                                }}
                            >
                                <Grid2Header title="Новый ученик" />
                                <AddStudent />
                            </Box>
                        </Grid2>

                        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 7 }}>
                            <Box
                                sx={{
                                    bgcolor: 'background.paper',
                                    border: 1,
                                    borderColor: 'var(--mui-palette-divider)',
                                    borderRadius: 1,
                                }}
                            >
                                <Grid2Header title="Список учеников" />
                                <Students />
                            </Box>
                        </Grid2>
                    </Grid2>
                </BoxCenterMain>
            </BoxCenterRoot>
        </>
    )
}

export default StudendsPage

