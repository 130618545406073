
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { alertModel } from '.';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Alert() {

    const alert = alertModel.selectors.useAlert()
    // const [open, setOpen] = React.useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        alertModel.events.setAlert(null);
    };

    return (
        <Dialog
            open={!!alert?.status}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{alert?.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {alert?.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{ paddingBottom: 2, paddingRight: 2 }}
            >
                <Button onClick={handleClose}>{alert?.action.cancel.text}</Button>
                <Button variant="outlined" color={alert?.action.success.color} onClick={alert?.action.success._click}>{alert?.action.success.text}</Button>
            </DialogActions>
        </Dialog>
    );
}
