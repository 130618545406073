import { createEffect, createEvent, createStore, sample } from "effector"
import { useStore } from "effector-react"
import { groupAPI } from "shared/api/events"
import { TGroup } from "shared/api/events/group"

const {
    addGroup,
    editGroup,
    delGroup,
    setGroups,
    setSelectGroup
} = groupAPI.events

export const $groupsStrore = createStore<TGroup[]>([])
    .on(setGroups, (_, groups) => groups)
    .on(addGroup, (groups, group) => [group, ...groups])
    .on(editGroup, (groups, group) => groups.map(c => {
        if (c.id === group?.id) return group
        return c
    }))
    .on(delGroup, (groups, id) => {
        return groups.filter(group => group.id !== id)
    })

const selectGroup = createEvent<number>()

export const $selectGroupStrore = createStore<TGroup | null>(null)
.on(setSelectGroup, (_, group) => group)


type TSelectGroups = {
    id: number
    groups: TGroup[]
}

const selectGroupFx = createEffect(({ id, groups }: TSelectGroups): TGroup | null => {
    if (id === null) return null
    return groups.filter(group => group.id === id)[0]
})

sample({
    clock: selectGroup,
    source: $groupsStrore,
    fn: (groups, id) => ({ groups, id }),
    target: selectGroupFx
})

sample({
    clock: selectGroupFx.doneData,
    target: $selectGroupStrore
})

const openModal = createEvent<boolean>()
export const $openModalStore = createStore<boolean>(false)
    .on(openModal, (_, modal) => modal)
    
const useOpenModal = () => useStore($openModalStore)

const useGroups = () => useStore($groupsStrore)
const useSelectGroup = () => useStore($selectGroupStrore)

export const selectors = {
    useGroups,
    useSelectGroup,

    useOpenModal
}

export const events = {
    openModal,
    selectGroup
}