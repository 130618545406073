import { Fragment, useEffect, useState } from 'react';

import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { PATH_AVATAR_MIN } from 'shared/config';
import { studentModel } from 'entities/student';
import { TStudent } from 'shared/api/events/student';

import { Checkbox, ListItemButton, Rating } from '@mui/material';

import { TTooltip } from 'shared/ui/tooltip';
import { lessonModel } from 'entities/lesson';
import { getGradesLessonAPI, gradeLessonAPI, TRespLessonGrade } from 'shared/api/requests/grade';

import css from './styles.module.scss'
import { userModel } from 'entities/user';
import { studentAPI } from 'shared/api/events';
import { getStudentsAPI } from 'shared/api/requests/student';

export type TGrade = -1 | 0 | 1 | 2 | 3 | 4 | 5

type TStudentGrade = {
    student_id: number
    grade: TGrade
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

export default function Journal() {

    const user = userModel.selectors.useUser()

    const students = studentModel.selectors.useStudents() || []

    const lesson = lessonModel.selectors.useLesson()

    const [grades, setGrades] = useState<TStudentGrade[]>([])

    console.log('grades', grades)

    const gradeLesson = (student_id: number, grade: TGrade) => {
        lesson?.id && gradeLessonAPI(student_id, lesson.id, grade)
            .then(grade => {
                console.log('gradeLessonAPI grade', grade.data)
            })
    }

    useEffect(() => {
        let _grades: TRespLessonGrade[] = []

        lesson?.id && getGradesLessonAPI(lesson?.id)
            .then(({ data }) => {
                _grades = data

                grades?.length === 0 && setGrades(students.map(student => ({
                    student_id: student.id as number,
                    grade: _grades.find(grade => grade.student_id === student.id)?.grade || 0
                })))


            })
            .catch(() => {
                grades?.length === 0 && setGrades(students.map(student => ({
                    student_id: student.id as number,
                    grade: 0
                })))
            })

    }, [lesson])

    return (
        <List sx={{ width: '100%', p: 0, m: 0 }}>
            {students.length === 0 ?
                <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                        p: 6,
                        textAlign: 'center'
                    }}
                >
                    Нет учеников
                </Typography> :
                students.map((student, i) => {

                    const _grade = grades?.find(grade => grade.student_id === student.id)?.grade

                    console.log('_grade', _grade)

                    return (
                        <Fragment key={student.id}>
                            <ListItem
                                disablePadding

                                sx={{ cursor: 'pointer' }}
                                alignItems="flex-start"
                                secondaryAction={<div className={css.journalAction}>
                                    <Rating
                                        size='medium'
                                        name="simple-controlled"
                                        value={_grade === -1 ? 0 : Number(_grade)}
                                        defaultValue={0}
                                        readOnly={user?.role === 'student'}
                                        onChange={(event, newValue) => {
                                            if (user?.role === 'employee') return

                                            setGrades(grades => grades.map(grade => {
                                                if (grade.student_id === student.id) {

                                                    gradeLesson(grade.student_id, newValue as TGrade)

                                                    return {
                                                        student_id: grade.student_id,
                                                        grade: newValue as TGrade
                                                    }
                                                }
                                                return grade
                                            }))
                                        }}
                                    />
                                    <TTooltip title={_grade !== -1 ? "Присутствует" : "Отсутствует"} placement='top'>
                                        <Checkbox
                                            {...label}
                                            defaultChecked
                                            color="success"
                                            checked={_grade !== -1 ? true : false}
                                            onChange={(event, newValue) => {
                                                if (user?.role === 'student') return

                                                setGrades(grades => grades.map(grade => {
                                                    if (grade.student_id === student.id) {
                                                        const g = !newValue ? -1 : 0 as TGrade
                                                        gradeLesson(grade.student_id, g)
                                                        return {
                                                            student_id: grade.student_id,
                                                            grade: g
                                                        }
                                                    }
                                                    return grade
                                                }))
                                            }}
                                        />
                                    </TTooltip>
                                </div>}
                            >
                                <ListItemButton>
                                    <ListItemAvatar>
                                        <Avatar alt={`${student.firstname} ${student.lastname}`} src={PATH_AVATAR_MIN + student.image} />
                                    </ListItemAvatar>

                                    <ListItemText
                                        onClick={(e) => {
                                            console.log('click user?.id', student?.id)
                                            studentModel.events.selectStudent(student?.id || 0)
                                            student?.id && studentModel.events.openModal(true)
                                        }}
                                        primary={`${student.firstname} ${student.lastname}`}
                                    />

                                </ListItemButton>
                            </ListItem>
                            {students.length - 1 > i && (
                                <Divider
                                    variant="inset"
                                    component="li"
                                />
                            )}
                        </Fragment>
                    )
                })}
        </List>
    );
}