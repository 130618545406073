import { createEffect, createEvent, createStore, sample } from "effector"
import { useStore } from "effector-react"
import { courseAPI } from "shared/api/events"

export type TCourse = {
    id?: number
    hash: string
    icon: string
    name: string
    more: string
    lessons: number
    lang: '' | 1 | 2
}

const {
    addCourse,
    editCourse,
    delCourse,
    setCourses,
    setSelectCourse
} = courseAPI.events

export const $coursesStrore = createStore<TCourse[]>([])
    .on(setCourses, (_, courses) => courses)
    .on(addCourse, (courses, course) => [course, ...courses])
    .on(editCourse, (courses, course) => courses.map(c => {
        if (c.id === course?.id) return course
        return c
    }))
    .on(delCourse, (courses, id) => {
        return courses.filter(course => course.id !== id)
    })

const selectCourse = createEvent<number>()

export const $selectCourseStrore = createStore<TCourse | null>(null)
    .on(setSelectCourse, (_, course) => course)

type TSelectCourses = {
    id: number
    courses: TCourse[]
}

const selectCourseFx = createEffect(({ id, courses }: TSelectCourses): TCourse | null => {
    if (id === null) return null
    return courses.filter(course => course.id === id)[0]
})

sample({
    clock: selectCourse,
    source: $coursesStrore,
    fn: (courses, id) => ({ courses, id }),
    target: selectCourseFx
})

sample({
    clock: selectCourseFx.doneData,
    target: $selectCourseStrore
})

const openModal = createEvent<boolean>()
export const $openModalStore = createStore<boolean>(false)
    .on(openModal, (_, modal) => modal)

const useOpenModal = () => useStore($openModalStore)

const useCourses = () => useStore($coursesStrore)
const useSelectCourse = () => useStore($selectCourseStrore)

export const selectors = {
    useCourses,
    useSelectCourse,

    useOpenModal
}

export const events = {
    openModal,
    selectCourse
}