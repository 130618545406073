import { createEvent, createStore } from "effector"
import { useStore } from "effector-react"

const setSnackbar = createEvent<TSnackbar | null>()

type TSnackbar = {
    status: boolean
    text: string
    severity: 'success' | 'error'
}

export const $snackbarStore = createStore<TSnackbar | null>(null)
    .on(setSnackbar, (_, snack) => snack)

const useSnackbar = () => useStore($snackbarStore)

export const selectors = {
    useSnackbar,
}

export const events = {
    setSnackbar
}