import * as React from 'react';
import Bread from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { pageModel } from '../page-root';
import { TPage } from '../page-root/page-root/model';

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();

    // console.log(event.currentTarget?.href)
    // console.log(event.target?.href)
    console.info('You clicked a breadcrumb.');
}

type TBreadcrumbs = {
    icon: React.ReactNode
    name: string
    link: TPage
}

const Breadcrumbs: React.FC<{ links: TBreadcrumbs[] }> = ({ links }) => {
    return (
        <div role="presentation" onClick={handleClick}>
            <Bread aria-label="breadcrumb">

                {links.map(item => {
                    return (
                        <Link
                            key={item.link}
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            href={item.link}
                            onClick={(event) => {
                                event.preventDefault();
                                pageModel.events.setPage(event.currentTarget.getAttribute('href') as TPage)
                            }}
                        >
                            {item.icon}
                            {item.name}
                        </Link>
                    )
                })}
                {/* <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/groups"
                    onClick={(event) => {
                        event.preventDefault();
                        pageModel.events.setPage(event.currentTarget.getAttribute('href') as TPage)
                    }}
                >
                    <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Core
                </Link>
                <Typography
                    sx={{ color: 'text.primary', display: 'flex', alignItems: 'center' }}
                >
                    <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Breadcrumb
                </Typography> */}
            </Bread>
        </div >
    );
}

export default Breadcrumbs