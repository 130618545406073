import { FC, useEffect } from "react";

import { Box, Grid2 } from "@mui/material";

import HomeIcon from '@mui/icons-material/Home';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

import { PDF } from "shared/ui/pdf/ui";

import { courseModel } from "entities/course";
import { groupModel } from "entities/group";
import { lessonAPI, presentAPI, studentAPI } from "shared/api/events";
import { presentModel } from "entities/present";

import { Grid2Header } from "shared/ui/grid2header";
import { ListGroupItem } from "entities/group/ui/list-item";
import { ListCourseItem } from "entities/course/ui/list-item";
import { LessonAction } from "entities/lesson/ui/action";
import { getLessonAPI } from "shared/api/requests/lesson";

import Journal from "widgets/lesson/journal/ui";
import Breadcrumbs from "shared/ui/breadcrumbs/ui";
import BoxCenterRoot from "shared/ui/box-center/root";
import BoxCenterHeader from "shared/ui/box-center/header";
import BoxCenterMain from "shared/ui/box-center/main";

import { getPresentRefAPI } from "shared/api/requests/present";

import LockIcon from '@mui/icons-material/Lock';

import { studentModel } from "entities/student";
import { getStudentsAPI } from "shared/api/requests/student";

import css from './styles.module.scss'
import { selectLessonAPI } from "shared/api/select-lesson";

const LessonPage: FC = () => {

    const group = groupModel.selectors.useSelectGroup()

    const course = courseModel.selectors.useSelectCourse()
    const present = presentModel.selectors.useSelectPresent()
    const presentRef = presentModel.selectors.usePresentRef()

    const students = studentModel.selectors.useStudents()

    useEffect(() => {
        (students === null || (students && students[0].group_id !== group?.id)) && group?.id && getStudentsAPI(group.id)
            .then(stds => {
                studentAPI.events.setStudents(stds.data)
            })
    }, [students, group?.id])

    useEffect(() => {
        group?.id && present?.id && getLessonAPI(group.id, present.id)
            .then(lesson => {
                if (!lesson.data) return
                lessonAPI.events.setLesson(lesson.data)
            })

        return () => {
            lessonAPI.events.setLesson(null)
        }
    }, [group, present])


    useEffect(() => {
        present?.id && getPresentRefAPI(present.id)
            .then(ref => {
                presentAPI.events.setPresentRef(ref.data)
                selectLessonAPI(ref.data)
            })
        return () => {
            presentAPI.events.setPresentRef(null)
        }
    }, [present])


    if (!group || !course || !present || !presentRef) return <></>

    return (
        <BoxCenterRoot mw={1100}>
            <BoxCenterHeader>
                <Breadcrumbs
                    links={[
                        {
                            icon: <HomeIcon sx={{ mr: 0.8 }} fontSize="small" />,
                            name: 'Главная',
                            link: '/home'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: 'Курсы',
                            link: '/admin/courses'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: course?.name || 'Курс',
                            link: '/presents'
                        },
                        {
                            icon: <ImportContactsIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: present?.title || 'Урок',
                            link: '/presents'
                        },
                    ]}
                />
            </BoxCenterHeader>

            <BoxCenterMain>
                <Grid2 container spacing={2}>

                    <Grid2
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '16px'
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                            component="div"
                        >
                            {presentRef === 'no_access' ?
                                <div className={css.pdfNoAccess}>
                                    <div className={css.icon}>
                                        <LockIcon sx={{width: '60px', height: '60px'}}/>
                                    </div>
                                    <div className={css.text}>
                                        У вас ещё нет доступа к этому уроку
                                    </div>
                                </div> : <PDF
                                    load={true}
                                    url={`https://itansar.ru/php-server/presents/${presentRef}`}
                                />}
                        </Box>

                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <LessonAction
                                present={present}
                                group={group}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', gridGap: '16px' }}>

                            <Grid2
                                size={{
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                    lg: 5
                                }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '16px'
                                }}
                            >
                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        border: 1,
                                        borderColor: 'var(--mui-palette-divider)',
                                        borderRadius: 1,
                                    }}
                                >
                                    <Grid2Header title="Текущая группа" />
                                    <ListGroupItem
                                        id={group.id || 0}
                                        icon={group.icon}
                                        name={group.name}
                                        students={group.students}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        border: 1,
                                        borderColor: 'var(--mui-palette-divider)',
                                        borderRadius: 1,
                                    }}
                                >
                                    <Grid2Header title="Текущий курс" />
                                    <ListCourseItem
                                        id={course.id || 0}
                                        icon={course.icon}
                                        name={course.name}
                                        lessons={course.lessons}
                                    />
                                </Box>
                            </Grid2>

                            <Grid2
                                size={{
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                    lg: 7
                                }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '16px'
                                }}
                            >
                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        border: 1,
                                        borderColor: 'var(--mui-palette-divider)',
                                        borderRadius: 1,
                                    }}
                                >
                                    <Grid2Header title="Оценки за текущий урок" />
                                    <Journal />
                                </Box>
                            </Grid2>

                        </Box>
                    </Grid2>

                </Grid2>
            </BoxCenterMain>
        </BoxCenterRoot >
    )
}

export default LessonPage

