import { useStore } from "effector-react"
import { presentModel } from "entities/present"
import { createEffect, createEvent, createStore, sample } from "effector"
import { TPresent } from "entities/present/model"

const editPresent = createEvent<number>()

export const $editPresentStrore = createStore<TPresent | null>(null)

type TSelectPresents = {
    id: number
    presents: TPresent[]
}

const selectPresentsFx = createEffect(({ id, presents }: TSelectPresents): TPresent | null => {
    if (id === null) return null
    return presents.filter(c => c.id === id)[0]
})

sample({
    clock: editPresent,
    source: presentModel.$presentsStrore,
    fn: (presents, id) => ({ presents, id }),
    target: selectPresentsFx
})

sample({
    clock: selectPresentsFx.doneData,
    target: $editPresentStrore
})

const useEditPresent = () => useStore($editPresentStrore)

export const selectors = {
    useEditPresent,
}

export const events = {
    editPresent
}