import axios from "axios"
import { API_URL } from "shared/config"
import { TEmployee } from "../events/employee"
import { TResponseLessons } from "@ctypes/http"

export const addEmployeeAPI = (employee: TEmployee) => {
    const route = API_URL + '/add-employee'
    return axios.post(
        route, employee, {
        withCredentials: true
    })
}

export const editEmployeeAPI = (employee: TEmployee) => {
    const route = API_URL + '/edit-employee'
    return axios.post(
        route, employee, {
        withCredentials: true
    })
}

export const delEmployeeAPI = (id: number) => {
    const route = API_URL + '/del-employee'
    return axios.post(
        route, { id }, {
        withCredentials: true
    })
}

export const getEmployeesAPI = () => {
    const route = API_URL + '/get-employees'
    return axios.get<TEmployee[]>(
        route, {
        withCredentials: true
    })
}

export const getEmployeeAPI = (id: number) => {
    const route = API_URL + '/get-employee'
    return axios.post<TResponseLessons>(
        route, { id }, {
        withCredentials: true
    })
}