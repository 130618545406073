import { TControlStatus } from "@ctypes/model"
import { TConnectAPI } from "@ctypes/socket/client-to-server"
import { WS } from "processes/socket"

export const connectSocketAPI = (
    sign: string,
    status: TControlStatus
) => {
    const data: TConnectAPI = {
        event: 'connect',
        payload: {
            sign,
            status
        }
    }
    WS.sendData(data)
}