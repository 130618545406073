import { FC } from "react";
import { Divider, Typography } from "@mui/material";
import { ListCourseItem } from "entities/course/ui/list-item";

type TCourse = {
    id: number
    icon: string
    name: string
    lessons: number
}

type TCourseProps = {
    courses: TCourse[]
}

export const Courses: FC<TCourseProps> = ({ courses }) => {
    return (
        <>
            {courses.length > 0 ? courses.map(({ id, icon, name, lessons }, i) => {
                return (
                    <>
                        <ListCourseItem
                            id={id}
                            icon={icon}
                            name={name}
                            lessons={lessons}
                        />
                        {courses.length - 1 > i && <Divider />}
                    </>
                )
            }) : <Typography sx={{ pt: 12, pb: 12, textAlign: 'center', fontSize: '1.02rem' }}>
                Нет курсов
            </Typography>}
        </>
    )
}