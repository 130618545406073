import { FC } from "react";

import { Box, Button } from "@mui/material";
import GroupMenu from "../menu/ui";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { pageModel } from "shared/ui/page-root";
import { PATH_COURSE_NRM } from "shared/config";

import { groupModel } from "entities/group";

import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { training_days } from "entities/group/lib/days";
import { courseModel } from "entities/course";
import { TTrainingValue } from "features/group/add/ui";
import GroupsIcon from '@mui/icons-material/Groups';

import CourseLogo from 'shared/assets/icons/course.png'

import css from './styles.module.scss'

type TGroupProps = {
    id: number
    icon: string
    name: string
    course: number
    training: TTrainingValue[]
    students: number
    teacher: string | null
}

export const CardItemGroup: FC<TGroupProps> = ({
    id,
    icon,
    name,
    course,
    training,
    students,
    teacher
}) => {

    const courses = courseModel.selectors.useCourses()

    const open = () => {
        groupModel.events.selectGroup(id)
        pageModel.events.setPage('/students')
    }

    return (
        <Box
            sx={{
                bgcolor: 'background.paper',
                border: 1,
                borderRadius: 1,
                borderColor: 'var(--mui-palette-divider)',
            }}
            className={css.card}
        >
            <div className={css.header}>

                <div className={css.snack}>
                    <div className={css.icon}>
                        <img
                            width={54}
                            alt={name}
                            src={icon ? PATH_COURSE_NRM + icon : CourseLogo}
                        />
                    </div>
                    <div className={css.title}>
                        <div className={css.name}>
                            {name}
                        </div>
                        <div className={css.students}>
                            <GroupsIcon className={`${css.svg} custom-svg`} fontSize="small" />
                            <b>{students}</b>
                            <span>
                                {(() => students > 4 ? 'учеников' :
                                    students > 1 ? 'ученика' :
                                        students > 0 ? 'ученик' : 'учеников')()}
                            </span>
                        </div>
                    </div>
                </div>

                <div className={css.stat}>
                    <GroupMenu id={id} />
                </div>

            </div>

            <div className={css.main}>
                <div className={css.list}>
                    <div className={css.item}>
                        <div className={css.stack}>
                            <div className={css.icon}><AutoStoriesIcon className="svg-custom" /></div>
                            <div className={css.name}>Курс обучения</div>
                        </div>
                        <div className={css.value}>
                            {courses.filter(c => c.id === course)[0]?.name}
                        </div>
                    </div>
                    {/* <div className={css.item}>
                        <div className={css.stack}>
                            <div className={css.icon}><SupervisedUserCircleIcon className="svg-custom" /></div>
                            <div className={css.name}>В группе</div>
                        </div>
                        <div className={css.value}>
                            {students} {(() => {
                            return students > 4 ? 'учеников' :
                            students > 1 ? 'ученика' :
                            students > 0 ? 'ученик' : 'учеников'
                        })()}
                        </div>
                    </div> */}
                    <div className={css.item}>
                        <div className={css.stack}>
                            <div className={css.icon}><PermContactCalendarIcon className="svg-custom" /></div>
                            <div className={css.name}>Преподаватель</div>
                        </div>
                        <div className={css.value}>
                            {teacher ? teacher : 'Не указан'}
                        </div>
                    </div>
                    <div className={css.item}>
                        <div className={css.stack}>
                            <div className={css.icon}><CalendarMonthIcon className="svg-custom" /></div>
                            <div className={css.name}>Занятия</div>
                        </div>
                        <div className={css.value}>
                            {training.map(day => <span>{training_days[day[0]]}</span>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.menu}>
                <Button
                    size="medium"
                    variant="outlined"
                    color="primary"
                    startIcon={<OpenInFullIcon />}
                    onClick={open}
                >
                    Открыть
                </Button>
            </div>
        </Box>
    )
}