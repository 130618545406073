import { FC } from "react";
import { studentModel } from "entities/student";

import StudentContainer from "widgets/student";

const StudentPage: FC = () => {

    // Основные данные о студенте
    const student = studentModel.selectors.useSelectStudent()

    if (!student) return <></>

    return (
        <StudentContainer student={student} />
    )
}

export default StudentPage

