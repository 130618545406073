import { createEffect, createEvent, createStore, sample } from "effector"
import { useStore } from "effector-react"

const setPage = createEvent<'next' | 'prev'>()
const prevPage = createEvent()
const nextPage = createEvent()

const setNumPages = createEvent<number>()

export const $numPagesStore = createStore<number>(0)
    .on(setNumPages, (p, num) => num)

export const $pagenationStore = createStore<number>(1)
    .on(nextPage, (p, _) => p + 1)
    .on(prevPage, (p, _) => p - 1)

const setPageFx = createEffect(({ source, action }: TPage) => {
    if (action === 'next') source.page < Number(source.numPages || 0) && nextPage()
    else source.page > 1 && prevPage()
})

type TPage = {
    source: {
        page: number
        numPages: number
    }
    action: 'next' | 'prev'
}
sample({
    clock: setPage,
    source: {
        numPages: $numPagesStore,
        page: $pagenationStore
    },
    fn: (source, action) => ({ source, action }),
    target: setPageFx
})

const useNumPages = () => useStore($numPagesStore)
const usePage = () => useStore($pagenationStore)

export const selectors = {
    useNumPages,
    usePage,
}

export const events = {
    setPage,
    setNumPages
}