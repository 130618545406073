import { createStore } from "effector"
import { useStore } from "effector-react"
import { userAPI } from "shared/api/events"
import { TUser } from "shared/api/events/user"

const {
    setUser
} = userAPI.events

export const $userStore = createStore<TUser | null>(null)
    .on(setUser, (_, user) => user)

const useUser = () => useStore($userStore)

export const selectors = {
    useUser,
}