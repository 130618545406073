import { Box } from "@mui/material"
import { FC, ReactNode } from "react"

export const BoxCenterHeader: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Box sx={{ p: 2, pb: 0, width: '100%' }}>
            {children}
        </Box>
    )
}

export default BoxCenterHeader