import type { Navigation } from '@toolpad/core';

import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const NAVIGATION_STUDENT: Navigation = [
    {
        kind: 'header',
        title: 'Навигатор',
    },
    {
        segment: 'home',
        title: 'Главная',
        icon: <HomeIcon />,
    },
    {
        segment: 'user',
        title: 'Мой профиль',
        icon: <PersonIcon />,
    },
    {
        segment: 'rating',
        title: 'Рейтинг учеников',
        icon: <TrendingUpIcon />,
    },
    {
        kind: 'divider',
    },
    {
        kind: 'header',
        title: 'Дополнительно',
    },
    {
        segment: 'employees',
        title: 'Сотрудники',
        icon: <PeopleAltIcon />,
    },
];

export default NAVIGATION_STUDENT