import { FC } from "react";

import { ListItem, ListItemButton } from "@mui/material";
import { pageModel } from "shared/ui/page-root";
import { PATH_COURSE_NRM } from "shared/config";

import GroupsIcon from '@mui/icons-material/Groups';

import { groupModel } from "entities/group";

import CourseLogo from 'shared/assets/icons/course.png'

import css from './styles.module.scss'

type TGroup = {
    id: number
    icon: string
    name: string
    students: number
}

export const ListGroupItem: FC<TGroup> = ({ id, icon, name, students }) => {

    const open = (id: number) => {
        groupModel.events.selectGroup(id)
        pageModel.events.setPage('/group')
    }

    return (
        <ListItem disablePadding>
            <ListItemButton
                sx={{ p: 2 }}
                onClick={() => open(id)}
            >
                <div className={css.group_item}>
                    <div className={css.snack}>
                        <div className={css.icon}>
                            <img
                                width={54}
                                alt={name}
                                src={icon ? PATH_COURSE_NRM + icon : CourseLogo}
                            />
                        </div>
                        <div className={css.title}>
                            <div className={css.name}>
                                {name}
                            </div>
                            <div className={css.students}>
                                <GroupsIcon className={`${css.svg} custom-svg`} fontSize="small" />
                                <b>{students}</b>
                                <span>
                                    {(() => students > 4 ? 'учеников' :
                                        students > 1 ? 'ученика' :
                                            students > 0 ? 'ученик' : 'учеников')()}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={css.stat}>
                        {/* <GroupMenu id={id} /> */}
                    </div>
                </div>
            </ListItemButton>
        </ListItem>
    )
}