import React, { FC, useEffect, useState } from "react";

import { IconLoader } from "shared/assets/icons";
import { getCookie } from "shared/lib/get-cookie";
import { getFormData } from "shared/lib/get-form-data";

import { Button, Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

import { PATH_AVATAR_NRM } from "shared/config";
import { snackbarModel } from "shared/ui/snackbar";
import { studentAPI } from "shared/api/events";
import { PhoneMaskCustom } from "shared/ui/phone-mask";

import { addStudentAPI, editStudentAPI } from "shared/api/requests/student";
import { featureStudent } from "..";

import { groupModel } from "entities/group";

import css from './styles.module.scss'

type TValue = {
    value: string
    status: null | 'error' | 'success'
}

export const AddStudent: FC = () => {

    const editUser = featureStudent.selectors.useEditStudent()
    const selectGroup = groupModel.selectors.useSelectGroup()

    console.log('editUser', editUser)

    const groups = groupModel.selectors.useGroups()

    const default_value = {
        value: '',
        status: null
    }

    useEffect(() => {
        setUserImage({
            img: editUser?.image || '',
            hash: '',
            load: 0
        })

        setFirstname({
            value: editUser?.firstname || '',
            status: null
        })
        setLastname({
            value: editUser?.lastname || '',
            status: null
        })
        setPhone({
            value: editUser?.phone || '',
            status: null
        })
        setGroup({
            value: editUser?.group_id || selectGroup?.id || '',
            status: null
        })

    }, [editUser, selectGroup])

    const [userImage, setUserImage] = useState({
        img: '',
        hash: '',
        load: 0
    });

    const [firstname, setFirstname] = useState<TValue>(default_value);
    const [lastname, setLastname] = useState<TValue>(default_value);
    const [phone, setPhone] = useState<TValue>(default_value);

    const [group, setGroup] = React.useState<{
        value: number | ''
        status: null | 'error' | 'success'
    }>({
        value: selectGroup?.id || '',
        status: null
    });

    const checkFirstname = (value: string) => {
        setFirstname({
            value,
            status: null
        })
    }

    const checkLastname = (value: string) => {
        setLastname({
            value,
            status: null
        })
    }

    const checkPhone = (value: string) => {
        setPhone({
            value,
            status: null
        })
    }

    const checkGroup = (value: number) => {
        setGroup({
            value,
            status: null
        })
    }


    console.log('>>>> AddStudent oauth', getCookie('oauth'))

    const reset = () => {
        setFirstname(default_value);
        setLastname(default_value);
        setPhone(default_value);

        setGroup({
            value: selectGroup?.id || '',
            status: null
        })

        setUserImage({
            img: '',
            hash: '',
            load: 0
        });

    }

    function checkForm(): boolean {

        const isFirstname = firstname.value.trim().length > 0
        const isLastname = lastname.value.trim().length > 0
        const isPhone = phone.value.trim().length > 0

        if (!isFirstname) setFirstname({ ...firstname, status: 'error' })
        else setFirstname({ ...firstname, status: 'success' })

        if (!isLastname) setLastname({ ...lastname, status: 'error' })
        else setLastname({ ...lastname, status: 'success' })

        if (!isPhone) setPhone({ ...phone, status: 'error' })
        else setPhone({ ...phone, status: 'success' })

        return true &&
            isFirstname &&
            isLastname &&
            isPhone

    }

    const _addUser = (e: React.FormEvent) => {
        e.preventDefault()

        if (!checkForm()) {
            snackbarModel.events.setSnackbar({
                text: 'Заполните все поля!',
                severity: 'error',
                status: true
            })
            return
        }

        addStudentAPI({
            firstname: firstname.value,
            lastname: lastname.value,
            phone: phone.value,
            image: userImage.img,
            group_id: Number(group.value)
        })
            .then(response => {
                snackbarModel.events.setSnackbar({
                    text: 'Студент успешно добавлен!',
                    severity: 'success',
                    status: true
                })
                response?.data?.id && studentAPI.events.addStudent(response.data)
                reset()
            })
            .catch(function (e) {
            })
    }

    const _editUser = (e: React.FormEvent) => {
        if (!editUser?.id) return
        e.preventDefault()
        if (!checkForm()) {
            snackbarModel.events.setSnackbar({
                text: 'Заполните все поля!',
                severity: 'error',
                status: true
            })
            return
        }
        editStudentAPI({
            id: editUser?.id,
            firstname: firstname.value,
            lastname: lastname.value,
            phone: phone.value,
            image: userImage.img,
            group_id: Number(group.value)
        })
            .then(response => {
                snackbarModel.events.setSnackbar({
                    text: 'Данные студента успешно изменены!',
                    severity: 'success',
                    status: true
                })
                response?.data?.id && studentAPI.events.editStudent(response.data)
                reset()
            })
            .catch(function (e) { })
    }

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) return

        let img_file: File = e.target.files[0]
        let size_file = img_file.size / 1024 / 1024;

        if (size_file > 15) {
            //
        } else {
            if (img_file) {
                setUserImage({
                    img: '',
                    hash: '',
                    load: 1
                });
                uploadImage(img_file);
            }
        }
    }


    const uploadImage = (file: File) => {
        console.log('uploadImage oauth', getCookie('oauth'))

        fetch('https://itansar.ru/php-server/api/upload-form.php', {
            method: 'POST',
            body: getFormData({
                file,
                oauth: JSON.stringify({
                    token: getCookie('oauth')
                })
            })
        })
            .then(response => {
                return response.json()
            })

            .then(result => {
                console.log('result', result)
                if (result.res === "ok") {
                    setUserImage({
                        img: result.data.filename,
                        hash: result.data.hash,
                        load: 0
                    })
                } else {

                    setUserImage({
                        img: '',
                        hash: '',
                        load: 0
                    })

                    if (result.data.code === 2) {
                    } else if (result.data.code === 3) {
                    } else if (result.data.code === 5) {
                    } else {
                    }
                }

            })
            .catch(e => {
                console.log('catche', e)
                setUserImage({
                    img: '',
                    hash: '',
                    load: 0
                });
            });

    }

    return (

        <Container sx={{ pb: 2, pt: 1 }}>
            <form onSubmit={editUser ? _editUser : _addUser}>
                <FormControl fullWidth margin="normal">
                    <div className={`${css.group} ${css.flex}`}>
                        <div className={css.icon}>
                            {userImage.load === 1 ?
                                <div className={css.loader}>
                                    <IconLoader className="svg-custom svg-loader" fill="836EAD" />
                                </div> :
                                <img
                                    src={
                                        userImage.img === '' ?
                                            'https://itansar.ru/assets/user.png?s' :
                                            PATH_AVATAR_NRM + userImage.img
                                    }
                                    className="cc_image"
                                    alt='<>'
                                />}
                            <input
                                type='file'
                                onChange={(e) => onSelectFile(e)}
                                accept=".jpg, .jpeg, .png"
                            />
                        </div>
                    </div>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField
                        id="firstname"
                        label="Имя"
                        value={firstname.value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            checkFirstname(event.currentTarget.value)
                        }}
                        variant="filled"
                        fullWidth
                        error={firstname.status === 'error'}
                    />

                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField
                        id="lastname"
                        label="Фамилия"
                        value={lastname.value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            checkLastname(event.currentTarget.value)
                        }}
                        variant="filled"
                        fullWidth
                        error={lastname.status === 'error'}
                    />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField
                        id="phone"
                        label="Номер телефона"
                        value={phone.value}
                        name="phone"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            checkPhone(event.target.value)
                        }}
                        variant="filled"
                        fullWidth
                        slotProps={{
                            input: {
                                inputComponent: PhoneMaskCustom as any,
                            },
                        }}
                        error={phone.status === 'error'}
                    />
                </FormControl>
                <FormControl variant="filled" fullWidth margin="normal">
                    <InputLabel id="group-label">Группа</InputLabel>
                    <Select
                        id="group"
                        disabled={!!selectGroup}
                        labelId="group-label"
                        label="Группа"
                        value={String(group.value)}
                        onChange={(event: SelectChangeEvent) => {
                            checkGroup(event.target.value as unknown as number)
                        }}
                        variant="filled"
                        fullWidth
                        error={group.status === 'error'}
                    >
                        {groups.map(group => {
                            return (
                                <MenuItem value={group.id}>{group.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                <FormControl
                    fullWidth
                    margin="normal"
                >
                    <Button size="large" variant="contained" fullWidth onClick={editUser ? _editUser : _addUser}>
                        {editUser ? 'Сохранить' : 'Зарегистрировать'}
                    </Button>
                </FormControl>
            </form>
        </Container>
    )
}