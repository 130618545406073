import { FC, useEffect, useState } from "react";
import { Box, Grid2, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { Grid2Header } from "shared/ui/grid2header";

import Breadcrumbs from "shared/ui/breadcrumbs/ui";
import HomeIcon from '@mui/icons-material/Home';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import { employeeModel } from "entities/employee";
import { CardUser } from "entities/employee/ui/card";
import { Schedule } from "widgets/employee/schedule";
import { getEmployeeAPI } from "shared/api/requests/employee";
import { Groups } from "widgets/employee/groups";
import { Courses } from "widgets/employee/courses";
import { TEmployee } from "shared/api/events/employee";

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { userModel } from "entities/user";
import { logoutAPI } from "shared/api/requests/user";
import { userAPI } from "shared/api/events";
import BoxCenterRoot from "shared/ui/box-center/root";
import BoxCenterHeader from "shared/ui/box-center/header";
import BoxCenterMain from "shared/ui/box-center/main";

type TSchedule = {
    day: number
    group: string
    course: string
    time: number
}

type Group = {
    id: number
    icon: string
    name: string
    students: number
}
type Course = {
    id: number
    icon: string
    name: string
    lessons: number
}

type TProps = {
    employee: TEmployee
}

const EmployeeContainer: FC<TProps> = ({ employee }) => {

    const [groups, setGroups] = useState<Group[]>([])
    const [courses, setCourses] = useState<Course[]>([])

    const [schedule, setSchedule] = useState<TSchedule[]>([])

    const user = userModel.selectors.useUser()

    useEffect(() => {
        employee?.id && getEmployeeAPI(employee.id)
            .then(employee => {
                const _schedule: TSchedule[] = []

                employee.data.lessons.forEach(lesson => {
                    console.log(">> lesson", lesson)
                    lesson.training?.forEach(([day, time]) => {
                        _schedule.push({
                            day,
                            group: lesson.group,
                            course: lesson.course,
                            time
                        })
                    });
                })

                setGroups(employee.data.groups)
                setCourses(employee.data.courses)
                setSchedule(_schedule)

            })
    }, [employee])

    if (!employee) return <></>

    const logout = () => logoutAPI().then(() => userAPI.events.setUser({
        role: 'guest',
        user: null
    }))

    return (
        <BoxCenterRoot mw={1100}>

            <BoxCenterHeader>
                <Breadcrumbs
                    links={[
                        {
                            icon: <HomeIcon sx={{ mr: 0.8 }} fontSize="small" />,
                            name: 'Главная',
                            link: '/home'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: 'Сотрудники',
                            link: '/employees'
                        },
                        {
                            icon: <AutoStoriesIcon sx={{ mr: 0.8 }} fontSize="inherit" />,
                            name: `${employee?.firstname} ${employee?.lastname}`,
                            link: '/employee'
                        },
                    ]}
                />
            </BoxCenterHeader>

            <BoxCenterMain>
                <Grid2 container spacing={2}>

                    <Grid2
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 5.5,
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '16px'
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="О сотруднике" />
                            <CardUser
                                id={employee?.id || 0}
                                firstname={employee.firstname}
                                lastname={employee.lastname}
                                email={employee.email}
                                image={employee.image}
                                job={employee.job}
                                phone={employee.phone}
                            />
                        </Box>
                        {user?.user?.id === employee.id && user?.role === 'employee' &&
                            <Box
                                sx={{
                                    bgcolor: 'background.paper',
                                    border: 1,
                                    borderColor: 'var(--mui-palette-divider)',
                                    borderRadius: 1,
                                }}
                            >
                                {/* <Grid2Header title="Настройки" /> */}
                                <List>
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            onClick={logout}
                                            sx={{ gridGap: '16px' }}
                                        >
                                            <ExitToAppIcon />
                                            <ListItemText primary={'Выйти из аккаунта'} />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Box>}
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Расписание" />
                            <Schedule schedule={schedule || []} />
                        </Box>
                    </Grid2>

                    <Grid2
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 6.5
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '16px'
                        }}>

                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Группы" />
                            <Groups groups={groups} />
                        </Box>

                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: 1,
                                borderColor: 'var(--mui-palette-divider)',
                                borderRadius: 1,
                            }}
                        >
                            <Grid2Header title="Курсы" />
                            <Courses courses={courses} />
                        </Box>
                    </Grid2>
                </Grid2>
            </BoxCenterMain>
        </BoxCenterRoot>
    )
}

export default EmployeeContainer

