import Typography from '@mui/material/Typography';
import { courseModel } from 'entities/course';
import { Box } from '@mui/material';
import { CardItemCourse } from 'entities/course/ui/card-item';

export default function Courses() {

    const courses = courseModel.selectors.useCourses()

    return (
        <>
            {courses.length === 0 ?
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        border: 1,
                        borderRadius: 1,
                        borderColor: 'var(--mui-palette-divider)',
                    }}
                >
                    <Typography variant="h6" component="h6" sx={{ pt: 12, pb: 12, textAlign: 'center' }}>
                        Ещё нет курсов
                    </Typography>
                </Box> : courses.map((course, i) => {
                    return (
                        <>
                            <CardItemCourse
                                id={course.id || 0}
                                hash={course.hash}
                                icon={course.icon}
                                title={course.name}
                                more={course.more}
                                lessons={course.lessons}
                            />
                        </>
                    )
                })}
        </>
    );
}