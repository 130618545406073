import axios from "axios"
import { API_URL } from "shared/config"
import { TStudent } from "../events/student"
import { TGroup } from "../events/group"
import { TCourse } from "entities/course/model"

export const addStudentAPI = (employee: TStudent) => {
    const route = API_URL + '/add-student'
    return axios.post(
        route, employee, {
        withCredentials: true
    })
}

export const editStudentAPI = (employee: TStudent) => {
    const route = API_URL + '/edit-student'
    return axios.post(
        route, employee, {
        withCredentials: true
    })
}

export const delStudentAPI = (id: number) => {
    const route = API_URL + '/del-student'
    return axios.post(
        route, { id }, {
        withCredentials: true
    })
}

export const getStudentsAPI = (group_id: number) => {
    const route = API_URL + '/get-students'
    return axios.post<TStudent[]>(
        route, { group_id }, {
        withCredentials: true
    })
}

export const getStudentAPI = (id: number) => {
    const route = API_URL + '/get-student'
    return axios.post<{
        student: TStudent
        group: TGroup
        course: TCourse
    }>(
        route, { sid: id }, {
        withCredentials: true
    })
}


export const getRatingAPI = () => {
    const route = API_URL + '/get-rating'
    return axios.post<TStudent[]>(
        route, {}, {
        withCredentials: true
    })
}