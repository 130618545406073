import { FC } from "react";
import { employeeModel } from "entities/employee";

import EmployeeContainer from "widgets/employee";

const EmployeePage: FC = () => {
    // Основные данные о сотруднике
    const employee = employeeModel.selectors.useSelectEmployee()

    if(!employee) return <></>

    return (
        <EmployeeContainer employee={employee} />
    )
}

export default EmployeePage

