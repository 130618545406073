import { createEvent } from "effector"
import { TLesson } from "entities/lesson/model"

const setLesson = createEvent<TLesson | null>()
const startLesson = createEvent<TLesson>()
const finishLesson = createEvent()

const setLessons = createEvent<TLesson[]>()

export const events = {
    setLesson,
    startLesson,
    finishLesson,
    
    setLessons
}