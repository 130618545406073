import { Handlers, THandlers } from "shared/api/handlers"

import { WS_HOSTNAME } from "shared/config"
import { socketModel } from "shared/api/socket"
import { Socket } from "shared/api/socket/socket"
import { ControlHandler } from "shared/api/handlers/control"

export const WS = Socket.create(WS_HOSTNAME, socketModel.events.setSocketStatus)

const handlers = new Handlers({
    [ControlHandler.EVENT]: new ControlHandler(),
} as THandlers)

WS.setHandlers(handlers.handle())

// Ты думал, как назвать входящие и исходящие запросы в сокетах..............